.reveal input.reset-password[type="submit"] {
  width: 100%;
  max-width: 100%;
  margin-top: 40px;
}

.password-content {
  text-align: center;
  padding-bottom: 50px;
  p {
    max-width: 800px;
    margin: auto;
    text-align: center;
    margin-bottom: 50px;
  }
  input {
    max-width: 550px;
    width: 100%;
    margin: 20px auto;
    &[type="submit"] {
      margin-top: 50px;
    }
  }
}
