.button {
  display: block;
  text-decoration: none;
  font-size: rem-calc(18);
  letter-spacing: 1.5px;
  padding: 15px 15px;
  background: $brand;
  border: 1px solid $brand;
  color: $white;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  margin: 38px auto;
  min-width: 250px;
  max-width: 350px;
  cursor: pointer;
  transition: .5s background-color;
  border-radius: 4px;

  &.small {
    height: 38px;
    font-size: rem-calc(14);
    margin: 28px auto;
    min-width: 92px;
    padding: 7.5px 12.5px;
  }

  &.share {
    height: 38px;
    font-size: rem-calc(14);
    margin: 28px auto;
    padding: 7.5px 12.5px;
    border-radius: 2px;
    background-image: url(../../../img/icon-social-share.svg);
    background-position: 97% 6px;
    background-repeat: no-repeat;
    background-color: $white;
    color: $rose !important;
    border-color: $rose !important;
    text-align: left;
    max-width: 150px;
    background-size: 22px;

    &:hover {
      background-color: $white;
      color: $rose !important;
      border-color: $light !important;
    }
  }

  &.mini {
    height: 32px;
    min-width: 0px;
    max-width: 140px;
    font-size: rem-calc(11px);
    padding: 8px 6px;

    &.no-max-width{
      min-width: inherit;
      max-width: inherit;
      display: inline;
    }
  }

  &:disabled {
    background: $light;
    border: 1px solid $light;

    &:hover {
      background: $lighter;
      cursor: default;
      color: $white;
    }
  }

  @include breakpoint(medium) {
    width: inherit;
  }

  &:hover {
    background-color: transparent;
    color: $brand;
  }

  &.clear {
    background: transparent;
    color: $brand;

    &:hover {
      background: $brand;
      color: $white;
    }

    &.white {
      background: transparent;
      color: $white;
      border-color: $white;
      &:hover {
        background: $white;
        color: $vb-black;
      }
    }
  }

  &.green {
    background: $offset;
    color: $white;
    border-color: $offset;
    &:hover {
      background: transparent;
      color: $offset;
    }
  }

  &.green.clear {
    background: transparent;
    color: $offset;
    border-color: $offset;
    &:hover {
      background: $offset;
      color: $white;
    }
  }

  &.white {
    background: $white;
    color: $rose;
    border-color: $rose;
    &:hover {
      background: $rose;
      color: $white;
    }
  }

  &.grey {
    background: transparent;
    color: $dark;
    border-color: $dark;
    &:hover {
      background: $dark;
      color: $white;
    }
  }

  &.prime {
    background: $prime;
    border-color: $prime;
    &:hover {
      color: $prime;
      background: transparent;
    }
  }

  &.backdrop {
    &:hover {
      background: $white;
    }
  }

  &.left {
    float: left;

    @include breakpoint (medium down) {
      float: none;
    }
  }

  &.right {
    float: right;

    @include breakpoint (medium down) {
      float: none;
    }
  }

  &.icon {
    border-radius: 3px;
    border: solid 1px #D05A56;
    position: relative;
    display: block;
    width: 32px;
    min-width: 32px;
    height: 32px;
    background-color: transparent;
    margin: 0;

    &.contact {

      background-color: $rose;
      background-image: url(../../../img/icon-newmessage-white.svg);
      background-repeat: no-repeat;
      background-position: top right;
      background-size: 29px;
      position: relative;

      &:hover {
        background-color: transparent;
        background-image: url(../../../img/icon-newmessage.svg);
      }
    }

    &.delete {
      &::before,
      &::after {
        content: "";
        position: absolute;
        background: $rose;
        transform: rotate(45deg);
      }

      &::before {
        left: 14px;
        top: 2px;
        width: 1px;
        height: calc(100% - 4px);
      }

      &::after {
        top: 15px;
        left: 2px;
        height: 1px;
        width: calc(100% - 4px);
      }

      &:hover {
        background-color: $rose;
        &::before,
        &::after {
          background: white;
        }
      }
    }
  }
}