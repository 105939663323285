@font-face {
  font-family: 'nexa_boldregular';
  src: url('../../../fonts/nexa_bold-webfont.woff2') format('woff2'),
  url('../../../fonts/nexa_bold-webfont.woff') format('woff'),
  url('../../../fonts/nexa_bold.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nexa_lightregular';
  src: url('../../../fonts/nexa_light-webfont.woff2') format('woff2'),
  url('../../../fonts/nexa_light-webfont.woff') format('woff'),
  url('../../../fonts/nexa_light.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
