.countdown-container {

  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;

    background: #CC5D59;
    background: #CC5D59;
    padding-top: 20px;
    padding-bottom: 20px;

    .intro-txt {
      color: #2C2C2C;
      font-family: 'Open Sans', sans-serif;
      font-size: 28px;
      line-height: 38px;
      text-align: center;
      padding-bottom: 5px;
      .white {
        color: white;
      }
    }

  .flip-clock {
    text-align: center;
    perspective: 600px;
    margin: 0 auto;
  
    *,
    *:before,
    *:after { box-sizing: border-box; }
  }
  
  .flip-clock__piece {
    display: inline-block;
    margin: 0 0.2vw;
    
    @media (min-width: 1000px) {
      margin: 0 5px;
    }
  }
  
  .flip-clock__slot {
    font-size: 1rem;
    line-height: 1.5;
    display: block;
  /*
    //position: relative;
    //top: -1.6em;
    z-index: 10;
    //color: #FFF;
  */
  }
  
  $halfHeight: 0.72em;
  $border-radius: 0.15em;
  
  .flip-card {
    display: block;
    position: relative;
    padding-bottom: $halfHeight;
    font-size: 2.25rem;
    line-height: 0.95;
  }
  
  @media (min-width: 1000px) {
    .flip-clock__slot { font-size: 1.2rem; }
    .flip-card { font-size: 3rem; }
  }
  
  
  /*////////////////////////////////////////*/
  
  
  .flip-card__top,
  .flip-card__bottom,
  .flip-card__back-bottom,
  .flip-card__back::before,
  .flip-card__back::after {
    display: block;
    height: $halfHeight;
    color: #ccc;
    background: #222;
    padding: 0.23em 0.25em 0.4em;
    border-radius: $border-radius $border-radius 0 0;
    backface-visiblity: hidden;
    transform-style: preserve-3d;
    width: 1.8em;
  }
  
  .flip-card__bottom,
  .flip-card__back-bottom {
    color: #FFF;
    position: absolute;
    top: 50%;
    left: 0;
    border-top: solid 1px #000;
    background: #393939;
    border-radius: 0 0 $border-radius $border-radius;
    pointer-events: none;
    overflow: hidden;
    z-index: 2;
  }
  
  .flip-card__back-bottom {
    z-index: 1;
  }
  
  .flip-card__bottom::after,
  .flip-card__back-bottom::after {
    display: block;
    margin-top: -$halfHeight;
  }
  
  .flip-card__back::before,
  .flip-card__bottom::after,
  .flip-card__back-bottom::after {
    content: attr(data-value);
  }
  
  .flip-card__back {
    position: absolute;
    top: 0;
    height: 100%;
    left: 0%;
    pointer-events: none;
  }
  
  .flip-card__back::before {
    position: relative;
    overflow: hidden;
    z-index: -1;
  }
  
  .flip .flip-card__back::before {
    z-index: 1;
    animation: flipTop 0.3s cubic-bezier(.37,.01,.94,.35);
    animation-fill-mode: both;
    transform-origin: center bottom;
  }
  
  .flip .flip-card__bottom {
    transform-origin: center top;
    animation-fill-mode: both;
    animation: flipBottom 0.6s cubic-bezier(.15,.45,.28,1);// 0.3s;
  }
  
  @keyframes flipTop {
    0% {
      transform: rotateX(0deg);
      z-index: 2;
    }
    0%, 99% {
      opacity: 1;
    }
    100% {
      transform: rotateX(-90deg);
      opacity: 0;
    }
  }
  
  @keyframes flipBottom {
    0%, 50% {
      z-index: -1;
      transform: rotateX(90deg);
      opacity: 0;
    }
    51% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotateX(0deg);
      z-index: 5;
    }
  }
  

}