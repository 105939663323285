* {
  font-family: "proxima-nova";
}

.center {
  &,
  & .title-small {
    text-align: center;
  }
}

a {
  text-decoration: none;
}

p {
  font-size: rem-calc(14px);

  a {
    text-decoration: underline;
  }

  &.small {
    font-size: rem-calc(12px);
  }
  &.large {
    font-size: rem-calc(16px);
  }
  &.large-x {
    font-size: rem-calc(20px);
  }  
  &.quote {
    text-align: center;
    font-style: italic;
    font-size: rem-calc(28px) !important;
    letter-spacing: 1px;
    color: $offset;
    font-weight: 600;
    border-top: 1px solid $light;
    border-bottom: 1px solid $light;
    max-width: 800px;
    margin: 50px auto 0;
    padding: 30px 0;
  }
}


.article {
  p.quote {
    font-size: rem-calc(28);
  }
}

ul.no-style {
  margin: 0;
  padding: 0;
  li {
    list-style-type: none;
  }
}

p.info-green {
  font-size: rem-calc(18px);
  color: $offset;
  margin-top: 0;
}

blockquote {
  text-align: center;
  font-style: italic;
  font-size: rem-calc(28px) !important;
  letter-spacing: 1px;
  color: $offset;
  font-weight: 600;
  border-top: 1px solid $light;
  border-bottom: 1px solid $light;
  max-width: 800px;
  margin: 50px auto 0;
  padding: 30px 0;
}