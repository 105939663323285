/* VB-specific overrides for Foundation tabs component (resets only, individual page styles included in that page) */
.tabs {
  border:none;
  padding: 0;
}

.tabs-title {
  > a,
  a:hover,
  a:focus,
  a[aria-selected='true'] {
    color: $dark;
    background: transparent;
  }
}

.tabs-content {
  border:none;
  color: $vb-black;
}

.tabs-panel {
  padding: 1rem 0;
}

