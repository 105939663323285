.supplier-sidebar {
	position: relative;
	
	@include breakpoint(large) {
		&:after {
			content: '';
			position: absolute;
			border-left: 1px solid $light;
			width: 1px;
			height: 100%;
			top: 0;
			left: -48px;
		}
	}

	.supplier-info {
	  background-color: #e8e8e8;
	  padding: 20px;
	  max-width: 400px;
	  margin: 0 auto;

	  .title-small {
	  	font-weight: bold;
	  	margin-bottom: 20px;
	  }

	  ul {
	  	margin: 0;
	  	padding: 0;

	  	li {
	  		list-style-type: none;
	  		font-weight: 600;
	  	}
	  }

	  .tel {
			font-size: rem-calc(25px);
			font-weight: 600;
			line-height: 42px;
			margin: 20px 0 0;

			@include breakpoint(medium) {
				font-size: rem-calc(30px);
			}

			@include breakpoint(1280px) {
				font-size: rem-calc(35px);
			}		
		}

		.button {
			margin-bottom: 0;
		}
	}

	.pdf-wrap {
		margin: 36px auto 0;
	  max-width: 400px;
	}

	ul.btn-stacker-inline{
		margin: 0 0 20px;
    padding: 0;
    text-align: center;		
	}

	.supplier-docs {
		display: block;
		text-align: center;
		color: inherit;
		margin-bottom: 10px;
	}
}


.supplier-slideshow {
	position: relative;
	margin-top: 40px;
	margin-bottom: 40px;

	@include breakpoint(large) {
		margin-bottom: 0;
	}
}

.supplier-video {
	@include breakpoint(large) {
		height: 100%;
		min-height: 400px;
	}
}

.char-limit {
	text-align: right;
	margin-top: 0;
	font-size: rem-calc(14px);
}


#supplier-enquiry {
	//override serach form border color
	.search-form input:not([type="submit"]) {
		border: 2px solid $light;
	}
}