.suppliers-feature {

  @include breakpoint(large) {
    max-width: 1374px;
  }

  .title-small {
    text-align: center;
  }

  .wrap {
    display: block;
    color: inherit;
    max-width: 418px;
    margin: 0 auto 40px;

    .img {
      height: 220px;
      width: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: 20px;
    }

    .title-small {
      @include breakpoint(large) {
        text-align: left;
      }
    }

    p {
      font-size: inherit;
      max-width: 90%;
      margin: 14px auto 20px;
      text-align: center;

      @include breakpoint(large) {
        text-align: left;
        max-width: 98%;
        margin-left: 0;
        margin-right: 0;
      }
    }

    a {
      @include breakpoint(large) {
        margin: 0;
      }
    }
  }
}


.suppliers-search {

  @include breakpoint(medium){
    .supplier-cell {
      position: relative;
  
      .text {
        display: block;
        height: auto;

        p {
          min-height: 64px;
        }
        p.address {
          min-height: 70px;
        }        
      }
    }
  }
  @include breakpoint(large){
    .supplier-cell { 
      .text {
        p {
          min-height: 50px;
        }
        p.address {
          min-height: 70px;
        }        
      }
    }
  }


  .accordion-title {
    background-color: $geyser;
    border-bottom-color: $geyser;

    @include breakpoint(large) {
      display: none;
    }

    &:before {
      content: '';
      height: 16px;
      width: 16px;
      background-image: url("../../../img/filter.svg");
      background-position: center;
      background-size: 100%;
    }
  }

  .accordion-content {
    @include breakpoint(large) {
      display: block;
    }
  }

  .image {
    width: 100%;
    max-width: 310px;
    margin: 23px auto;
    @include breakpoint(large) {
      margin: 0 auto;
    }
    height: 148px;
    background-position: center;
    background-size: cover;
  }

  .text {
    text-align: center;
    max-width: 310px;
    margin: 0 auto 40px;
    @include flexbox();
    @include flex-direction(column);
    height: 100%;
    width: 100%;

    @include breakpoint(small only) {
      height: auto;
    }

    .button {
      margin-top: auto;
      // @include flexbox();
      // @include flex-direction(column);
      // @include justify-content(flex-end);
    }


   @include breakpoint(medium) {
      text-align: left;
    }
  }

  
  .recommend-supplier {
    .text {
      height: calc(100% - 148px);

      @include breakpoint(small only) {
        height: auto;
      }
    }
  }
  .address {
    font-size: rem-calc(16);
    font-weight: 600;
  }

  .button {
    @include breakpoint(medium) {
      min-width: initial;
    }
    @include breakpoint(large) {
      min-width: 100%;
    }
  }
}

.suppliers-nav {
  background-color: $mystic;
  padding: 20px;

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;

      &:last-child {
        a {
          border-bottom: none;
        }
      }

      a {
        position: relative;
        display: block;
        text-transform: uppercase;
        border-bottom: 1px solid $light;
        padding: 10px 4px 10px 0px;

        &.active {
          &:hover {
            &:before {
              opacity: 0;
            }
          }
        }

        &:before {
          content: '';
        }

        &:hover {
          &:before {
            position: absolute;
            top: 16px;
            right: 12px;
            background-color: transparent;
            border: solid 1px $rose;
            transform: rotate(45deg);
            width: 12px;
            height: 12px;
            margin: 0;
            border-radius: 2px;
          }
        }
      }
    }
  }
}


.highlight {
  background-color: $mystic;
  padding: 40px 0;

  &.image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    > .grid-container {
      z-index: 1;
      position: relative;
    }

    &::after {
      display: block;
      content: '';
      background: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  blockquote {
    border: none;
    margin: 0 auto;
    padding: 0 6px;

    @include breakpoint(medium) {
      padding: 0;
    }

    p {
      color: $vb-black;
      font-size: rem-calc(26px);
      font-style: italic;
      line-height: 32px;
      text-align: center;
      margin-bottom: 32px;
      margin-top: 0;
    }

    cite {
      position: relative;
      padding-top: 24px;
      font-size: rem-calc(18px);
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 23px;
      text-transform: uppercase;
      color: $rose;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: -30px;
        right: 0;
        height: 1px;
        background-color: #CB5D59;
        width: calc(100% + 60px);
      }
    }
  }
}

.highlight-blog {
  .title {
    text-align: center;
    margin-top: 0;

    @include breakpoint(large) {
      text-align: left;
    }
  }

  p {
    text-align: center;
    max-width: 80%;
    margin: 0 auto 30px;

    @include breakpoint(large) {
      text-align: left;
      max-width: none;
    }
  }
  .button {
    @include breakpoint(large) {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0;
    }
  }

  img {
    display: block;
    margin: 0 auto;

    @include breakpoint(large) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.highlight-plan-event {
  .title-logo {
    margin-top: 0;
  }

  .button.small {
    margin-bottom: 0;
  }
}

.advert-color {
  background-color: $mystic;
}

.advert {
  padding-top: 40px;
  padding-bottom: 40px;

  .title {
    margin-top: 0;
    font-weight: 600;
  }

  @include breakpoint(large) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .title {
    margin-bottom: 4px;
    text-align: center;

    @include breakpoint(large) {
      text-align: left;
      font-size: rem-calc(40px);
    }
  }
  p {
    margin: 0 0 10px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;

    @include breakpoint(large) {
      text-align: left;
      margin-bottom: 0;
    }
  }
  .button {
    text-align: center;
    width: 100%;
    max-width: 330px;
    margin: 0 auto;
    display: block;

    @include breakpoint(large) {
      max-width: none;
    }
  }
}
