body.generic {
  section.page-content {

    @include breakpoint(medium) {
      padding: 0 50px;
    }
    
    .logo {
      width: 43px;
      height: 43px;
      margin: auto;
      margin-bottom: 50px;
      display: block;
    }

    div.quote .large {
      text-align: right;
      max-width: 800px;
      margin: 20px auto 50px;
    }
  }
}

.stripe-options {
  div.check {
    p {
      &.large {
        &.options {
          span {
            font-weight: bold;
            display: block;
          }
        }
      }
    }

    &:last-child {
      margin-bottom: 40px;
    }
  }
}

