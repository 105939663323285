section.your-details {

  input[type="text"] {
    height: 30px;
    font-size: rem-calc(14px);
    border: none;
    border-radius: 0;
    margin: 0 0 6px;

    &[readonly] {
      background: transparent !important;
      cursor: default;
      box-shadow: none;
    }

    &:focus {
      background: $lighter;
    }
  }

  .button.small {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  p, .tooltip-title {
    font-size: rem-calc(14px);
    font-weight: 600;
    margin: 0;
    padding-top: 5px;
    text-transform: uppercase;
  }

  h2 {
    position: relative;
    span {
      font-size: rem-calc(12px);
      margin-left: 6px;
    }
  }
  .change-password {
    padding: 20px;
    text-align: right;
    position: relative;

    @include breakpoint(small down) {
      text-align: center;
      border-top: 0;
    }

    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      width: calc(100% - 40px);
      margin: 0 auto;
      height: 2px;
      background-color: $light;
    }

    a {
      text-transform: uppercase;
      font-weight: 600;

      span {
        display: inline-block;
        margin-bottom: -4px;
        margin-right: 5px;
      }
    }
  }

  span.edit {
    color: $brand;
    font-size: 0.7rem;
    @include breakpoint(small down) {
      display: none;
    }
  }
}
