/*Dashboard Banner*/
.banner {
  background-size: cover;
  background-position: center;
  text-align: center;
  position: relative;
  color: $white;
  border-bottom: 10px solid $white;

  .grid-x {
    height: 50px;

    @include breakpoint(medium) {
      height: 200px;
    }

    .title-wrap {
      @include breakpoint(medium) {
        margin-top: 64px;
      }
    }
  }

  .title, .title-small {
    margin-top: 0;
    margin-bottom: 0;
  }

  .edit {
    position: absolute;
    top: 0;
    right: 36px;

    a {
      min-width: inherit;
    }
  }

  .type-wrapper {
    width: 400px;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    &:before{
      content: '';
      background-image: url(../../../img/watermark.svg);
      background-position: 50% 30px;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }

    @include breakpoint(medium down) {
      width: 300px;
      &:before {
        background-position: 50% 60px;
        background-size: contain;
      }
    }
    @include breakpoint(small down) {
      width: 180px;
      left: auto;
      right: 0;
      height: calc(100% + 60px);
      bottom: -40px;
      &:before {
        background-position: 50% 10px;
        background-size: cover;
      }
    }
  }

  .type {
    width: 150px;
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 2px 2px 0 0;
    p {
      font-size: rem-calc(16px);
      padding: 4px 0;
      margin: 0;
      color: $white;
      text-align: center;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: .5px;

      @include breakpoint(medium) {
        padding: 5px 0 2px;
        font-size: rem-calc(18px);
      }
    }
  }

  & > * {
    z-index: 2;
    position: relative;
  }

  &:after {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    content: '';
    z-index: 0;
    background: rgba(0, 0, 0, .6);
  }

  @include breakpoint(small down) {
    padding: 20px 10px 40px;
    text-align: left;
    border-bottom: 5px solid;
  }

}


.dashboard-silver {
  .banner {
    border-color: $sub-silv;
  }

  .type {
    background: $sub-silv;
  }
}

.dashboard-gold {
  .banner {
    border-color: $sub-gold;
  }

  .type {
    background:  $sub-gold;
  }
}

.dashboard-platinum {
  .banner {
    border-color: $sub-plat;
  }

  .type {
    background: $sub-plat;
  }
}

.dashboard-brand {
  .banner {
    border-color: $brand;
  }

  .type {
    background: $brand;
  }
}
