ul.social {
	text-align: center;
	margin: 0 0 40px;
	padding: 40px 0 0;
	position: relative;

	@include breakpoint(medium) {
		padding-top: 0;
		text-align: right;
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		background-color: $white;
		height: 1px;
		width: 100%;
		max-width: 335px;
		margin: 0 auto;
		left: 0;
		right: 0;

		@include breakpoint(medium) {
			display: none;
		}
	}

	li {
		list-style-type: none;
		text-align: center;
		text-transform: uppercase;
		margin-bottom: 16px;
		display: inline-block;
    margin-right: 20px;

    @include breakpoint(medium) {
    	margin-right: 14px;
    }

		&:last-child {
			margin-bottom: 0;
    	margin-right: 0;
		}
	}
}