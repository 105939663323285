div.member-intro {
  display: flex;
}
p.member-intro {
  max-width: 800px;
  margin: auto;
  text-align: center;
  padding: 50px 0;
}

table.member {
  tr {
    &.dulled {
      th {
        padding-left: 0;
        padding-right: 0;
        span {
          display: block;
          border-right: 1px solid $dark;
          padding: 0 20px;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        border-color: $mystic;
      }
    }
  }
}

section.your-estimates {
  @include breakpoint(medium) {
    padding: 0 20px 50px;
  }
}

table.estimates {
  margin-top: 20px;
  tr {
    th {
      color: $brand;
      background: $white;
      border: 0;
      border-bottom: 10px solid white;
      width: 166px;
    }
    td:first-child, th:first-child {
      background: $lighter;
      border-bottom: 10px solid white;
      border-right: 10px solid white;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    td:nth-child(even), th:nth-child(even) {
      background: $mystic;
    }
  }

  tbody {
    tr {
      td {
        border: 0;
        &.hide-bg {
          background: 0;
        }
        p {
          margin: 0;
          font-weight: 600;
          font-size: rem-calc(16px);
          span {
            font-weight: 500;
            display: block;
            font-size: rem-calc(12px);
            padding-bottom: 10px;
          }
        }
      }
      &.value {
        td {
          &.cost-title {
            border: 0;
          }
          &:nth-child(even) {
            background: $geyser;
          }
          &:nth-child(odd) {
            background: $mystic;
          }
          &.value {
            font-size: rem-calc(22px);
            font-weight: 600;
            text-align: center;
          }
        }
      }
    }
  }
}

div.event-summary {
  padding: 0 20px;

  .large {
    font-size: rem-calc(19px);
  }

  .morecontent span {
    display: none;
  }
  .morelink {
    display: block;
  }
}
.event-slider {
  .slick-track {
    width: 100%;
  }

  button {
    background-color: rgba(0, 0, 0, 0.5);
    height: 30px;
    width: 30px;
    background-size: 10px;
  }
  .slick-prev {
    left: 0;
  }
}

section.event-summary {
  table {
    tr {
      td {
        border: 0;
        padding: 6px 16px;
        p {
          margin: 0;
        }
        &:first-child {
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: 1px;
        }
      }
    }
  }
}

.admin-wrapper section .supplier-overview {
  margin-top: 40px;
}

.member-dashboard-help {
  max-width: 50%;
  margin: 50px auto;
  text-align: center;

  p {
    margin-bottom: 30px;
  }
}

body.member-event {
  .admin-wrapper section {
    padding: 10px 20px;
  }
}


.tooltip-main-title {
  top: -14px;
  right: 1px;
}
