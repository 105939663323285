@mixin flexbox() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin flex-direction($val) {
    -webkit-flex-direction: $val;
    flex-direction: $val;
}

@mixin justify-content($val) {
    -webkit-box-pack: $val;
    -moz-box-pack: $val;
    -ms-flex-pack: $val;
    -webkit-justify-content: $val;
    justify-content: $val;
}

@mixin align-items($val) {
    -webkit-box-align: $val;
    -moz-box-align: $val;
    -ms-flex-align: $val;
    -webkit-align-items: $val;
    align-items: $val;
}

@mixin align-content($val) {
    -webkit-align-content: $val;
    align-content: $val;
}

@mixin flex-wrap($val) {
    -webkit-flex-wrap: $val;
    flex-wrap: $val;
}

@mixin transitions($args...) {
  transition: $args;
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  
}

@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}
// rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
} 
// scale
@mixin scale($scale) {
     @include transform(scale($scale));
} 
// translate
@mixin translate ($x, $y) {
   @include transform(translate($x, $y));
}
// skew
@mixin skew ($x, $y) {
   @include transform(skew(#{$x}deg, #{$y}deg));
}

// calculate em from pixels
$browser-context: 16; // Default

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em
}

/*Box Shadow*/
@mixin box-shadow($top, $left, $blur, $color, $inset:"") {
 -webkit-box-shadow:$top $left $blur $color #{$inset};
 -moz-box-shadow:$top $left $blur $color #{$inset};
  box-shadow:$top $left $blur $color #{$inset};
}

/*Border Radius*/
@mixin rounded($radius: 20px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin placeholder-color($color) {
  //font-size: em(18);
  &::-webkit-input-placeholder { /* WebKit browsers */
      color: $color;
  }
  &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      color: $color;
  }
  &::-moz-placeholder { /* Mozilla Firefox 19+ */
      color: $color;
  }
  &:-ms-input-placeholder { /* Internet Explorer 10+ */
      color: $color;
  }
}

