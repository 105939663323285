// Primary Colours
$brand: #cb5d59;
$offset: #71a0a2;
$prime: #d8a867;
$airy: #3e495b;

// Strong Colours
$strong: #b10000;
$full: #f5a623;

// Greys
$lighter: #e8e8e8;
$light: #cccccc;
$dark: #979797;
$darker: #6d6d6d;

// Black
$vb-black: #383838;
$black: $vb-black;

//other
$light-brand: #faeeee;
$rose: #D05A56;
$mystic: #F1F5F6;
$geyser: #DDE8E8;
$jet-stream: #B4CCCD;

// subscription colors
$sub-silv: #ADAAA9;
$sub-gold: #E2B164;
$sub-plat: #7A7084;