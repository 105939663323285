body.blog.listings {
  .title-logo-red {
    @include breakpoint(medium) {
      pointer-events: none;
    }
  }
}

.filtering {
  text-align: right;
  select {
    margin: 0 auto;
    display: block;
    width: 320px;
    color: $dark;
    letter-spacing: 1px;
    font-size: rem-calc(18px);
    border: 0;
    border-radius: 0;
    padding-right: 15px;
    background-position: 98% 10px;
    border-bottom: 1px solid $vb-black;
    @include breakpoint (small down) {
      display: block;
      width: 100%;
    }
  }
}

section.blogs {
  padding-top: 30px;

  .grid-x {
    margin: 0 -50px;

    @include breakpoint (small down) {
      margin: 0;
    }
  }
  .blog {
    @include breakpoint(large) {
      padding-left: 50px;
      padding-right: 50px;
      padding-bottom: 50px;
    }

    .details {
      color: $dark;
    }

    .type {
      color: $brand;
      text-transform: uppercase;
      font-size: rem-calc(14px);
      letter-spacing: 1px;
    }
    a {
      display: block;
      transition: .4s all;
      margin: 0 auto 40px;
      max-width: 457px;
      color: $vb-black;
      text-align: center;

      @include breakpoint(large) {
        text-align: left;
      }

      &:hover {
        opacity: .8;
      }
    }
    .title-small {
      font-weight: 600;
      line-height: 28px;
      text-transform: none;

      a {
        color: $vb-black;
      }
    }
  }

  .blog-load {
    text-align: center;
    padding: 0 0 200px;
    a {
      min-width: 400px;

      @include breakpoint(small down) {
        min-width: 200px;
      }
    }
  }
}



section.social-icons {
  ul {
    text-align: center;
    margin: 0;
    padding: 0;
    li {
      list-style-type: none;
      display: inline-block;
      padding: 0 10px;
      img {
        width: 43px;
        height: 43px;
      }
    }
  }
}

article.blog {
  padding-bottom: 200px;
  p {
    font-size: rem-calc(18px);
  }
  .pre-text {
    text-align: center;
    letter-spacing: 1px;
  }
  .body {
    text-align: center;

    p {
      margin: 40px auto 40px;
    }
  }
  .details {
    text-align: center;
    h3 {
      font-size: rem-calc(16px);
      font-weight: 600;
      margin: 0px 0 26px;
      text-align: center;
    }
    p {
      font-size: rem-calc(15px);
      max-width: 340px;
      margin: 0 auto;
    }
  }
}


.blog {
  .banner-overlay {
    padding-top: 50px;
  }
}

.blog-video {
  width: 100%;
  height: 458px;
}
