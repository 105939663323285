.banner-title {
  min-height: 175px;  
  height: 30vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  top: 0;

  @include breakpoint(large) {
    height: 50vh;
    top: -96px;
    padding: 50px 0;
  }

  @include breakpoint(xlarge) {
    padding: 40px 0 120px;
  }

  .text-wrapper {
    position: relative;
    z-index: 1;
    left: 0;
    right: 0;
    z-index: 9;
    max-width: 1240px;
    margin: -24px auto 0;
    padding-left: 50px;
    padding-right: 50px;
    bottom: auto;
    top: 20%;

    @include breakpoint(large) {
      top: 30%;
    }

    @include breakpoint(xlarge) {
      top: 40%;
    }

    .title-banner {
      margin-bottom: 4px;
    }
    .title-small {
      font-size: rem-calc(14px);
      color: $white;
      margin-top: 0;

      @include breakpoint(large) {
        font-size: inherit;
        max-width: 50%;
      }
    }
  }

  &:after {
    display: block;
    content: '';
    background: rgba(0, 0, 0, 0.50);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
