header {
  top: 0;
  width: 100%;
  position: relative;
  z-index: 101;
  background: $vb-black;

  /*.grid-container {
    max-width: 1240px;
  }*/

  @include breakpoint(large) {
    background-color: transparent;
  }

  .topbar {
    height: 65px;
    margin-top: 0;
    margin-bottom: 0;  

    @include breakpoint(large) {
      height: 96px;
    }
  }

  .mobile {
    width: 150px;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .icon {
    display: inline-block;
    width: 65px;
    text-align: center;
    img {
      width: 30px;
    }
    a {
      color: $vb-black;
      text-transform: uppercase;
      font-size: .7rem;
      font-weight: 600;
    }
  }
  .logos {
    text-align: right;
  }

  .logo {
    background-image: url(../../../img/logo-mobile-red-white.svg);
    background-position: 0 0;
    background-repeat: no-repeat;
    height: 30px;
    display: block;

    @include breakpoint(large) {
      background-image: url(../../../img/logo-white.svg);
      height: 45px;
      margin-left: -32px;
    }
  }

  nav.highlights {
    display: none;

    @include breakpoint(large) {
      display: block;
    }

    ul {
      margin: 0;
      padding: 0;
      text-align: right;

      li {
        display: inline-block;
        vertical-align: middle;

        &.divider {
          margin: 0 3px;
        }

        a {
          color: $vb-black;
          text-transform: uppercase;
          padding: 10px;
          display: block;
          transition: .3s all;
          position: relative;
          font-weight: 500;
          font-size: 0.7rem;

          @include breakpoint(1280px) {
            padding: 10px 20px;
            font-size: 0.8rem;
          }

          &:hover, &.active {
            color: $white;

            &:after {
              width: calc(100% - 40px);
            }
          }

          &:after {
            display: block;
            width: 0;
            position: absolute;
            bottom: 5px;
            left: 0;
            right: 0;
            margin: auto;
            height: 1px;
            background: $white;
            content: '';
            transition: .3s all;
          }
        }
        &.your-account, &.log-out {
          a {
            color: $brand;
            padding-right: 0;
            &:after {background: $white; left: 20px;}
            &:hover { &:after {width: calc(100% - 20px);} }
          }
        }
        &.log-out a {
          padding-right: 20px;
          padding-left: 0;
          &:after {left: 0; right: 20px;}
        }
      }
    }
  }
}


header nav.highlights ul li.your-account a, header nav.highlights ul li.log-out a, header nav.highlights ul li a, header nav.highlights ul li {
  color: $white;
}


//White header styles
.theme-light {
  header nav.highlights ul li.your-account a, header nav.highlights ul li.log-out a, header nav.highlights ul li a, header nav.highlights ul li {
    color: $vb-black;

    &:after {
      background: $vb-black;
    }    
  }

  header {
    background: $white;

    .logo {
      background-image: url(../../../img/logo-mobile-red-black.svg);

      @include breakpoint(large) {
        background-image: url(../../../img/logo.svg);
      }
    }
  }
}


.off-canvas {
  position: fixed;
  width: 90%;
  background: $vb-black;
  top: 0;
  bottom: 0;
  right: -90%;
  z-index: 11;
  padding-left: 50px;
  padding-right: 50px;
  overflow-y: scroll;
  @include transitions(all .75s);

  @include breakpoint(medium) {
    width: 60%;
  }

  nav {
    position: relative;
    top: 7%;
    padding-bottom: 30px;
    border-bottom: 1px solid $white;

    ul {
      margin: 0;
      padding: 0;

      li {
        margin-bottom: 16px;
        padding: 0;

        &.active {
          a {
            color: $rose;
          }
        }

        &.divider {
          border-bottom: 1px solid $white;
          padding-bottom: 20px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        a {
          color: $white;
          text-transform: uppercase;
        }
      }
    }
  }

  .social {
    margin: 25px 0 0;

    &:after {
      display: none;
    }
  }
}

.off-canvas.active {
  right: 0;
}


.hamburger.is-active {
  position: fixed;
  top: 20px;
  right: 10px;
}