.reveal {
  text-align: center;
  position: relative;
  width: 100%;
  max-width: 730px;
  padding: 36px;

  .grid-x {
    margin-top: 0;
    margin-bottom: 0;
  }

  .title {
    margin-bottom: 30px;
    position: relative !important;
  }

  .copy {
    font-size: rem-calc(18px);
  }

  .radiocheck-list {
    text-align: left;
    max-width: 200px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-left: auto;

    li {
      &:first-child {
        margin-bottom: 10px;
      }
    }

    label {
      font-size: rem-calc(14px);
      line-hight: 16px;
    }

    input[type="radio"] + label span, input[type="checkbox"] + label span {
      margin-top: -1px;
    }
  }

  input[type="submit"] {
    margin-top: 20px;
  }

  .check {

    label {
      top: -5px;
      margin-right: 0px;
      position: relative;
    }
  }

  .planned-event {
    margin-top: 30px;
    position: relative;
    padding-top: 30px;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      border-top: 1px solid $vb-black;
      width: 66px;
      height: 1px;
    }

    .title {
      margin-bottom: 4px;
    }

    p {
      margin: 0 0 20px;
    }
  }

  .corp-fields {
    display: none;

    &.active {
      display: block;
    }
  }

  .held-date {
    font-size: rem-calc(18px);
    color: $rose;
    letter-spacing: 1px;
  }

  .booking-price {
    font-size: rem-calc(20px);

    span {
      color: $rose;
      font-size: rem-calc(24px);
    }
  }

  .signup-link {
    display: block;
    margin: 16px 0;
  }

  .forgot-password {
    display: block;
    text-align: right;
    margin-top: -5px;
    margin-right: 4px;
    margin-bottom: 20px;
  }

  .close-button {
    position: absolute;
    top: 8px;
    right: 10px;
    font-size: rem-calc(24px);
    color: $rose;
    cursor: pointer;
  }

  &.venue-slideshow {
    max-width: 1000px;
  }
}

table.book-table-detail {
  width: auto;
  text-align: center;
  margin: 0 auto;

  tbody {
    tr {
      td {
        vertical-align: top;
        padding: 4px 0 20px 0;
        border-right: 0;
        font-weight: 600;
        text-align: left;

        &:first-child {
          text-transform: uppercase;
          text-align: left;
          padding-right: 54px;
        }
      }
    }
  }
}

.deposit {
  color: $rose;
  font-size: rem-calc(22px);

  span {
    font-weight: bold;
  }
}

.rate-price {
  padding: 0;
  font-size: rem-calc(24px);

  span {
    color: $rose;
    font-weight: bold;
  }
}