  .reveal {

    #banner-uploadDDArea {
      position: relative;
      height: 105px;      
    }

    .multibox {

      &.uploadButton, &.file {
        width: 137px;
        margin: 0 auto;
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
      }
    }
  }


.multibox {

  &.file,
  &.uploadButton {
    border-radius: 4px;
    height: 105px;
    width: 137px;
    margin-left: 0;
  }

  &.file {
    padding: 0px;
    border: none;
    background: transparent;
    overflow: hidden;
    position: relative;
    margin-right: 15px;

    &:nth-child(5) {
      margin-right: 0px;
    }

    .picture_delete {
      position: absolute;
      background-image: url(/orakuploader/images/delete.png);
      background-repeat: no-repeat;
      background-size: cover;
      height: 40px;
      width: 40px;
      transform: translateX(-50%) translateY(-50%);
      left: 50%;
      top: 50%;
      opacity: 0.65;
      margin-top:0;
    }

    .picture_uploaded {
      max-width: 100%;
      max-height: 9999999999999px;
      width: 100%;
    }
  }


  &.uploadButton {
    padding: 15px 7.5px;
    background-color: $light;
    margin-right: 0;
    position: relative;

    img {
      width: 43%;
      position: absolute;
      transform: translateX(-50%) translateY(-50%);
      left: 50%;
      top: 50%;
    }

    *:not(img) {
      display: none;
    }
  }
}

.ui-sortable {

}