body.packages {
  .banner-overlay {
    p {
      max-width: 800px;
      margin: auto;
    }
  }
}

div.desktop-packages {
  background: $mystic;
  padding: 50px 0;
  max-width: 1000px;
  table {
    width: 90%;
    margin: auto;
    thead {
      tr {
        background: none;
        th {
          padding: 5px;
          text-align: center;
          background: none;
          border-right: 0;
          img {height: 20px;width: 20px;margin-right: 20px;}
          span {
            display: block;
            color: $white;
            padding: 5px;
            border-radius: 2px;
            font-size: rem-calc(18px);
            margin-bottom: 10px;
            &.silver {background: $sub-silv;}
            &.gold {background: $sub-gold;}
            &.platinum {background: $sub-plat;}
          }
          &:first-child {
            text-align: right;
          }
        }
      }
    }

    tr {
      td, th {
        &:nth-child(n + 2) {
          background: $white;
          border-right: 20px solid $mystic;
          min-width: 175px;
        }
        &:nth-child(n + 4) {
          border-right: 0;
        }
      }
    }

    tbody {
      tr {
        td {
          border-top: 1px solid $lighter;
          border-right: 0;
          padding: 5px 20px 0;
          font-weight: 600;
          color: $vb-black;
          text-align: center;
          font-size: rem-calc(14px);
          &:first-child {
            text-align: right;
            text-transform: uppercase;
          }
        }
      }
    }

    img {
      height: 28px;
      width: 28px;
      &[src*="unselected"] {
        filter: grayscale(100%);
      }
    }

    tfoot {
      tr:first-child {
        td {
          background: $lighter !important;
          border-right: 0 !important;
          border-top: 30px solid $mystic;
          padding: 20px;
          padding-right: 40px;
          font-size: rem-calc(20px);
          font-weight: 600;
          color: $vb-black;
          text-align: center;
          span {
            font-size: rem-calc(12px);
            font-weight: 500;
          }
        }
      }
      tr:nth-child(2n) {
        td {
          background: $mystic !important;
        }
      }
      a {
        min-width: auto;
        margin: 20px 0 0;
        font-weight: 500;
      }
    }
  }
}

div.instructions {
  padding: 50px 20px;
  background: $mystic;
  text-align: center;
  p {
    font-size: 1.2rem;
  }
  img {
    height: 24px;
    width: 24px;
  }
}

div.mobile-packages {

  .package {
    padding: 5px 0.46875rem;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      .package-title {border-bottom: 1px solid $lighter;}
    }
    .package-title {
      border-top: 1px solid $lighter;
      margin: 0;
      padding: 12px 0;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 600;
      font-size: rem-calc(12px);
      span {
        letter-spacing: inherit;
        font-weight: inherit;
        display: block;
        max-width: 170px;
        margin: auto;
        color: $white;
        padding: 5px;
        border-radius: 2px;
        font-size: rem-calc(24px);
        margin-bottom: 10px;
        //background: $light;
        background: $sub-silv;
        &.gold {background: $sub-gold;}
        &.platinum {background: $sub-plat;}
      }
      &.current {
        color: $brand;
      }
    }
    .content {
      border-top: 1px solid $lighter;
      background: $mystic;
      padding: 6px 20px 20px;
      display: none;

      p {
        text-align: left;
        padding-left: 40px;
        text-transform: uppercase;
        color: $vb-black;
        font-weight: 600;
        letter-spacing: 1px;
        position: relative;
        &:before {
          content: '';
          height: 24px;
          width: 24px;
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        &.selected {
          &:before {
            background: url(../../../img/icon-vbselected.svg);
          }
        }
        &.unselected {
          color: $dark;
          &:before {
            background: url(../../../img/icon-vbunselected.svg);
            filter: grayscale(100%);
          }
        }
      }

      .price {
        text-transform: uppercase;
        text-align: center;
        font-weight: bold;
        font-size: rem-calc(20px);
        background: $lighter;
        margin: 40px -1.71875rem 0;
        padding: 20px 0;
      }
    }

  }

}

section.see-how {

  padding: 50px 0;

  p.large {
    max-width: 800px;
    margin: auto;
  }

  .panels {
    .cell {
      position: relative;
      &:hover {
        .img-wrap a:after {
          background: rgba(0, 0, 0, 0.2);
        }
      }
      a {
        color: $white;
        text-decoration: none;
      }
      p {
        position: absolute;
        width: 200px;
        top: 0;
        left: 0;
        margin: 0;
        color: $white;
        font-size: rem-calc(22px);
        padding: 8px 12.5px;
        z-index: 1;
      }
      img {
        padding: 2.5px;
      }
      .img-wrap a:after {
        content: '';
        display: block;
        position: absolute;
        top: 2.5px;
        left: 2.5px;
        width: calc(100% - 5px);
        height: calc(100% - 5px);
        background: rgba(0, 0, 0, 0.5);
        z-index: 0;
        transition: .5s all;
      }
    }
  }
}
