footer {
	background-color: $vb-black;
	color: $white;
	padding: 27px 30px 120px;
	position: relative;
	overflow: hidden;

	@include breakpoint(medium) {
		padding-left: 0;
		padding-right: 0;
	}

	.grid-container {
		position: relative;

		@include breakpoint(1024) {
			&:after {
				content: '';
				position: absolute;
				top: -78px;
				right: 0px;
				background: url(../../../img/footer-diamond.svg) right 0 no-repeat;
				width: 100%;
				height: 140%;
				z-index: 2;
			}
		}
	}

	@include breakpoint(medium) {
		padding: 44px 16px 132px
	}

	@include breakpoint(large) {
		padding: 48px 16px;
	}

  .grid-x {
    margin-top: 0;
    margin-bottom: 0;
  }

	p {
		margin: 0px;
	}
}


footer.dashboard {
	padding: 27px 30px 170px;

	@include breakpoint(large) {
		padding-bottom: 118px;
	}
}

footer.dashboard.no-subscription-bar {
	padding-bottom: 120px;

	@include breakpoint(large) {
		padding-bottom: 38px;
	}
}

.dashboard.mobile-nav {
	margin: 0px 0 40px;
}

.dashboard.mobile-nav.no-subscription-bar {
	margin: 0;
}


.subscribe-form {
	position: relative;
	z-index: 3;
	text-align: center;
	margin-bottom: 60px;

	@include breakpoint(medium) {
		text-align: left;
		max-width: 90%;
	}

	@include breakpoint(large) {
		max-width: inherit;
	}	

	.title {
		margin-top: 0;
		margin-bottom: 10px;
		font-size: rem-calc(20px);

		@include breakpoint(medium) {
			font-size: rem-calc(16px);
			line-height: 20px;
		}
	}
	.copy {
		font-size: rem-calc(16px);
		margin-bottom: 40px;

		@include breakpoint(medium) {
			font-size: rem-calc(14px);
			margin-bottom: 40px;			
		}
	}

	form {
		position: relative;
	}

	input[type="email"]	{
		background-color: transparent;
		border: none;
		border-bottom: 1px solid $white;
		border-radius: 0;
		margin-bottom: 20px;
		max-width: 335px;
		margin: 0 auto 20px;
		color: $white;
		text-align: center;

		@include breakpoint(medium) {
			margin: 0;
			padding-left: 0px;
    	text-align: left;
			padding-right: 65px;
		}

		&:focus {
			box-shadow: none;
		}

		&::placeholder {
      text-align: center;

      @include breakpoint(medium) {
      	text-align: left;
      }
    }
	}
	input[type="submit"] {
		background: none;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
    border: none;
    padding-left: 0;    

		@include breakpoint(medium) {
			margin-top: 14px;
		}

    @include breakpoint(1320) {
    	position: absolute;
    	top: 1px;
    	right: 0;
    }
	}
}


ul.footer-nav {
	margin: 0 0 40px;
	padding: 0;
	position: relative;
	z-index: 3;

  @include breakpoint(medium) {
		max-width: 90%;
  }

  @include breakpoint(large) {
		max-width: inherit;
  }  

	li {
		list-style-type: none;
		text-align: center;
		text-transform: uppercase;
		margin-bottom: 16px;
    letter-spacing: 1px;

    @include breakpoint(medium) {
			text-align: left;
			margin-bottom: 10px;
    }

		&:last-child {
			margin-bottom: 0;
		}

		a {
			color: $white;
			position: relative;

			@include breakpoint(large) {

				&:after {
					content: '';
					position: absolute;
					bottom: -4px;
					height: 1px;
					width: 0;
					left: 0;
					background-color: $white;
					@include transitions(all 0.5s);
				}

				&:hover {
					&:after {
						width: 100%;
					}
				}
			}
		}
	}
}

.footer-info {
	font-size: rem-calc(11px);
	text-align: center;
	position: relative;
	z-index: 3;
	padding-top: 25px;

	@include breakpoint(medium) {
		text-align: left;
	}

	@include breakpoint(large) {
		padding-top: 30px;

		&:after{
			content: '';
			position: absolute;
			top: 10px;
			left: 0;
			width: 66%;
			height: 1px;
			background-color: $white;
		}
	}
}


.mobile-nav {
	position: fixed;
	bottom: 0;
	background-color: $vb-black;
	width: 100%;
	border-top: 1px solid $white;
	z-index: 51;

	@include breakpoint(large) {
		display: none;
	}

	ul {
		height: 90px;
		text-align: center;
		margin: 0 auto;
		padding: 0;
		@include flexbox();
		@include align-items(center);
		@include justify-content(space-evenly);

		li {
			list-style-type: none;

			a {
				display: block;
				color: $white;
				background-position: center 0;
				background-repeat: no-repeat;
				background-size: 30px;
    		padding-top: 40px;
    		text-transform: uppercase;
    		font-size: 11px;
    		letter-spacing: 1px;

				&.works {
					background-image: url(../../../img/icon-how-it-works.svg);
				}
				&.browse {
					background-image: url(../../../img/icon-browse.svg);
				}
				&.suppliers {
					background-image: url(../../../img/icon-suppliers.svg);
				}
				&.venue {
					background-image: url(../../../img/icon-list-venue.svg);
				}				
			}
		}
	}
}

.footer-logo {
	display: none;

	@include breakpoint(large) {
		margin-left: 24px;
		margin-bottom: 50px;
		display: block;
    width: 300px;
	}
}

ul.social {
	position: relative;
	z-index: 3;	
	margin: 70px 0 0;

	@include breakpoint(large) {
    margin: 112px 0 0;
	}

	li {
		@include breakpoint(large) {
			margin-bottom: 0;
		}

		a {
			display: block;
			width: 28px;
			height: 28px;
		}
	}
}

.footer-nav-small {

	li {
		font-size: rem-calc(12px);

		&:first-child {
			font-size: rem-calc(16px);
		}
	}
}


.subscription-bar {
  left: 0;
  right: 0;
  bottom: 91px;
  z-index: 50;
  position: fixed;
	
	@include breakpoint(large) {
		bottom: 0;
	}

	a {
		background-color: $brand;
		height: 40px;
		padding: 10px;
		display: block;
		color: $white;
		text-transform: uppercase;

		@include breakpoint(large) {
			height: 60px;
			padding: 18px;
			font-size: rem-calc(20px);
			text-transform: inherit;
		}

		.bold {
			font-weight: 600;
			text-transform: uppercase;
		}

		&.close {
			position: absolute;
			top: -15px;
			right: 20px;
			width: 30px;
			height: 30px;
			text-indent: -999999999999px;
			display: block;
			background: $offset;
			border-radius: 50%;

			&:before, &:after {
			  position: absolute;
			  content: ' ';
				height: 20px;
				top: 5px;			  
			  left: 14px;
			  width: 2px;
			  background-color: $white;

				@include breakpoint(large) {
			  	height: 26px;
					top: 6px;			  
				  left: 19px;
				}			  
			}
			&:before {
			  transform: rotate(45deg);
			}
			&:after {
			  transform: rotate(-45deg);
			}			

			@include breakpoint(large) {
				top: -20px;
				width: 40px;
				height: 40px;	
			}
		}
	}
}