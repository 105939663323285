.awesomplete [hidden] {
    display: none;
}

.awesomplete .visually-hidden {
    position: absolute;
    clip: rect(0, 0, 0, 0);
	left: 0;
}

.awesomplete {
    //display: inline-block;
    position: relative;
}

.awesomplete > input {
    display: block;
}

.awesomplete > ul {
    position: absolute;
    left: 0;
    z-index: 5555;
    min-width: 100%;
    box-sizing: border-box;
    list-style: none;
    padding: 0;
    margin: 0;
    background: #fff;
		-webkit-box-shadow: 2px 3px 17px -6px rgba(0,0,0,0.75);
		-moz-box-shadow: 2px 3px 17px -6px rgba(0,0,0,0.75);
		box-shadow: 2px 3px 17px -6px rgba(0,0,0,0.75);    
}

.awesomplete > ul:empty {
    display: none;
}

.awesomplete > ul {
	margin: -5px 0 0;
	background: $white;
}

@supports (transform: scale(0)) {
	.awesomplete > ul {
		transition: .3s cubic-bezier(.4,.2,.5,1.4);
		transform-origin: 1.43em -.43em;
	}
	
	.awesomplete > ul[hidden],
	.awesomplete > ul:empty {
		opacity: 0;
		transform: scale(0);
		display: block;
		transition-timing-function: ease;
	}
}

	/* Pointer */
	/*.awesomplete > ul:before {
		content: "";
		position: absolute;
		top: -.43em;
		left: 1em;
		width: 0; height: 0;
		padding: .4em;
		background: white;
		border: inherit;
		border-right: 0;
		border-bottom: 0;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
	}*/

	.awesomplete > ul > li {
		position: relative;
		padding: 10px .5em;
		cursor: pointer;
		text-transform: uppercase;
		letter-spacing: 1px;
	}
	
	.awesomplete > ul > li:hover {
		background: $white;
		color: black;
	}
	
	.awesomplete > ul > li[aria-selected="true"] {
		background: $white;
		color: $vb-black;
	}
	
		.awesomplete mark {
			background: $lighter;
		}
		
		.awesomplete li:hover mark {
			background: $lighter;
		}
		
		.awesomplete li[aria-selected="true"] mark {
			background: hsl(86, 100%, 21%);
			color: inherit;
		}