label {
  display: inline-block;
  font-family: "proxima-nova";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 19px;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 8px;
  color: $darker;

  &.left {
    width: auto;
    float: left;
  }

  &.right {
    width: auto;
    float: right;
  }
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

.input-wrap {
  margin: 0 0 8px;
}

input[type="text"], input[type="email"], textarea, select, input[type="password"], input[type="tel"], input[type="number"] {
  border: 2px solid $light;
  width: 100%;
  border-radius: 4px;
  font-size: 14px;
  background-color: $white;
  box-shadow: none;

  &.click2edit {
    border: none;
    background: transparent;
    text-transform: uppercase;
    transition: background 0.3s, box-shadow 0.5s, border-color 0.25s ease-in-out;

    &:focus,
    &:hover {
      background: $lighter;
    }

    &:focus {
      text-transform: none;
      outline: none;
      border: 1px solid $lighter;
      box-shadow: 0 0 5px $light;

    }
  }

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $light;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: $light;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: $light;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: $light;
  }

  textarea::-webkit-input-placeholder {
    color: $light;
  }

  textarea:-moz-placeholder { /* Firefox 18- */
      color: $light;
  }

  textarea::-moz-placeholder {  /* Firefox 19+ */
      color: $light;
  }

  textarea:-ms-input-placeholder {
      color: $light;
  }

  &.clean {
    @include breakpoint(large) {
      border: none;
    }
  }

}

input[type="text"], input[type="email"], select, input[type="password"], input[type="tel"], input[type="number"] {
  height: 50px;
  padding-left: 12px;
  padding-right: 20px;
  margin: 10px 0;
  font-size: rem-calc(18px);
}

textarea {
  padding-left: 20px;
  padding-right: 20px;
  margin: 10px 0;
  font-size: rem-calc(18px);
}

input[type="text"] {
  color: #6D6D6D;
}

textarea {
  min-height: 128px;
}

select{
  background-image: url(../../../img/icon-dropdown.svg);
  background-position: 98% 10px;
  background-repeat: no-repeat;
  background-size: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: $vb-black;
}

    select,
    select:invalid,
    select option[value=""] {
      color: $light;
    }

    select.selected,
    select option {
      color: $vb-black;
    }

//GDPR check
.check {
  padding-top: 20px;

  p {
    display: inline-block;
    // width: 88%;
    width: calc(100% - 70px);
    margin: 0;
    vertical-align: middle;
    text-align: left;
    font-size: rem-calc(14px);
  }
  label {
    width: auto;
  }
}

.required-note {
  color: $rose;
}

.alert {
  color: $rose;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}


.Zebra_DatePicker_Icon_Wrapper {
  width: 100%;
  max-width: 100%;

  @include breakpoint(medium) {
    width: inherit;
  }
}

input[type="submit"] {
  border: 1px solid $rose;

  &:hover {
    border: 1px solid $rose;
  }  
}

input:disabled, input[readonly], textarea:disabled, textarea[readonly] {
  cursor: text;
}

//When button are stacked gives spacing
ul.btn-stacker {
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    list-style-type: none;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
ul.btn-stacker-inline {
  padding: 0;
  
  li {
    display: inline-block;
    margin-bottom: 0;

    &:last-child {
      margin-right: 0;

      a{
        margin-right: 0;
      }
    }

    @include breakpoint(medium) {
      margin-right: 40px;
    }
  }
}


/*Radio/Checkbox Button Styling*/
input[type="radio"],
input[type="checkbox"] {
    display:none;
}

input[type="radio"] + label {
    color:inherit;
}

input[type="radio"],
input[type="checkbox"] {
  //& + label span:not(.top),
  & + label span:not(.has-tip) {
    display: inline-block;
    width: 13px;
    height: 13px;
    margin: -5px 12px 0 0;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid $vb-black;
  }

  &:checked {
    //& + label span:not(.top),
    & + label span:not(.has-tip) {
      border: 1px solid $vb-black;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        width: 5px;
        height: 5px;
        background-color: $vb-black;
        border-radius: 50%;
      }
    }
  }

  &:disabled {
    & + label span:not(.has-tip) {
      background-color: $white;
      border: 1px solid $light;
      &::after {
        display: none;
      }
    }
  }
}

input[type="checkbox"] { 
  //& + label span:not(.top),
  & + label span:not(.has-tip) {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: -5px 12px 0 0;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 50%;
    background-color: $white;
    border: 1px solid $rose;
  }

  &:checked {
    //& + label span:not(.top),
    & + label span:not(.has-tip) {
      background-color: $white;
      border: 1px solid $rose;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        width: 12px;
        height: 12px;
        background-color: $vb-black;
        border-radius: 50%;
      }
    }
  }
}

.dates {
  .dates-wrapper {
    margin: 0 auto;
    width: 210px;
  }

  input[type="radio"],
  input[type="checkbox"] {

    &.square {
      & + label {
        width: auto;
        float: left;
        display: block;
        margin: 0 5px;

        span {
          border-radius: 3px;
          border-width: 2px;
          position: relative;
          margin-right: 0;

          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 1px;
            width: 14px;
            height: 14px;
            background: inherit;
            text-align: center;
            color: $dark;
          }
        }
      }

      &[id^="monday"] + label span::after {
        content: 'M';
      }
      &[id^="tuesday"] + label span::after {
        content: 'T';
      }
      &[id^="wednesday"] + label span::after {
        content: 'W';
      }
      &[id^="thursday"] + label span::after {
        content: 'T';
      }
      &[id^="friday"] + label span::after {
        content: 'F';
      }
      &[id^="saturday"] + label span::after {
        content: 'S';
      }
      &[id^="sunday"] + label span::after {
        content: 'S';
      }
    }

    &.square {

      &:checked {
        & + label span {
          background-color: $rose;

          &::after {
            content: '';
            position: absolute;
            border-radius: 0px;
            left: 4px;
            top: -1px;
            width: 8px;
            height: 15px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
          }
        }
      }
    }

  }
}

input[type="radio"],
input[type="checkbox"] {

  &.tick {
    & + label {
      width: auto;
      float: left;
      display: block;
      margin: 8px 0;
      font-size: rem-calc(14px);
      font-weight: 500;
      text-transform: none;
      min-width: 260px;

      span {
        position: relative;
        margin-right: 10px;
        height: 16px;
        width: 16px;
        border: 1px solid $dark;
        border-radius: 2px;
        background-color: $white;
        box-shadow: inset 0 1px 2px 0 rgba(0,0,0,0.5);
      }

      + hr {

        width: 100%;
        display: inline-block;
        color: transparent;
        background: none;
        border-top: solid 1px $lighter;
      }
    }

    &:checked {
      & + label span {
        background-color: $white;

        &::after {
          content: '';
          position: absolute;
          border-radius: 0px;
          left: 6px;
          top: -3px;
          width: 7px;
          height: 14px;
          border: solid $rose;
          border-width: 0 3px 3px 0;
          transform: rotate(45deg);
          background-color: transparent
        }
      }
    }
  }
}



.accordion-content {
  & > input[type="checkbox"].tick,
  & > input[type="radio"].tick,
  .tabs.alphabet + .tabs-content input[type="checkbox"].tick,
  .tabs.alphabet + .tabs-content input[type="radio"].tick {
    + label {
      width: 100%;

      @include breakpoint(medium) {
        width: 49%;
      }

      @include breakpoint(large) {
        width: 33%;
      }
    }
  }
}


.radiocheck-list {
  margin: 0;
  padding: 0;
  li {
    list-style-type: none;
  }
}

form {
  label.error {
    color: $brand;
    text-transform: none;
    &:before {
      content: '* ';
    }
  }
  input.error {
    border: 2px solid $brand;
    &::-webkit-input-placeholder, &::-moz-placeholder, &:-ms-input-placeholder {
      color: $brand;
    }
  }
}

//Diamond on link
.active-diamond {
  position: relative;
  display: block;

  &:after{
    content: '';
    position: absolute;
    top: 17px;
    right: 12px;
    border-radius: 2px;
    transform: rotate(45deg);
    width: 10px;
    height: 10px;
    background-color: $rose;
    border-radius: 2px;
    display: block;
  }
}

form.send-message {
  text-align: right;

  @include breakpoint(small down) {
    text-align: center;
    padding: 5px 0;
    height: 144px;
    padding-top: 0px;
  }
  
  .border {
    border: 1px solid $light;
    padding: 10px 20px 20px;
  
    @include breakpoint(small down) {
      display: block;
      padding: 0;
      height: 76px;
      max-width: 80%;
      margin: 0 auto;
  
      textarea {
        min-height: 33px;
        height: 50px;
        width: 100%;
        padding: 7px 10px;

        &:focus {
          box-shadow: none;
        }        
      }
    }
  }
  
  input[type="text"] {
    width: 100%;
    appearance: none;
    border: 0;
    border-bottom: 1px solid $light;
    border-radius: 0;
  }

  textarea {
    border: 0;
    width: 100%;
    height: 100px;
    resize: none;
  }


  input[type="submit"] {
    margin-top: 20px;
    min-width: 100px;
    float: right;

    @include breakpoint(small down) {
      vertical-align: top;
      margin: 0 auto;
      min-width: 70px;
      margin-top: 12px;
      float: none;
    }
  }
}


.frm-split {
  position: relative;
  margin-bottom: 30px;


  &:after {
    content: '';
    position: absolute;
    bottom: -22px;
    left: -14px;
    height: 4px;
    width: calc(100% + 28px);
    background-color: #F1F6F6;
    z-index: 10;
  }

}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;

  .btn {
    display: block;
    text-decoration: none;
    text-transform: uppercase;
    margin: 0;
    cursor: pointer;
    transition: .5s background-color;
    border: solid 1px $rose;
    background-color: $white;
    color: $rose;
    height: 33px;
    width: 135px;
    border-radius: 2px;
    font-size: rem-calc(14px);
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;

    &:hover {
      background-color: $rose;
      color: $white;
    }
  }

  input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}

.guide-price-wrap {
  span.currency,
  label,
  input {
    color: $darker;
    font-size: rem-calc(30px);
    font-weight: 300;
    text-transform: none;
    float: left;
    &.small-size {
      font-size: rem-calc(18px);
    }
  }

  span {
    margin: 5px 10px 0px 5px;
  }

  input {
    height: 40px;
    width: 189px;
    border: 1px solid $dark;
    border-radius: 4px;
  }

  label {
    font-size: rem-calc(18px);
    width: auto;
    font-weight: 500;
    margin: 18px 0 0 10px;
  }

  &.disabled {
    span.currency {
      color:$dark;
    }

    input {
      border-color: $dark;
      pointer-events: none;
    }

    label {
      color:$dark;
    }
  }
}

input#other-text {
  color: $vb-black;
  font-size: rem-calc(30px);
  font-weight: 300;
  text-transform: none;
  float: left;
  height: 40px;
  width: 189px;
  border: 1px solid $vb-black;
  border-radius: 4px;

  &:disabled {
    border-color: $light;
  }
}

.disabled-input {
  position: relative;

}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance:textfield;
}


.form-float {
  padding: 0;
  margin: 0;

  li {
    list-style-type: none;
    width: 100%;

    @include breakpoint(medium){
      float: left;
      width: 65%;
    }

    &:first-child {
      @include breakpoint(medium){
        width: 32%;
        margin-right: 3%;
      }
    }
  }
}