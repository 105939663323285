.review {
  color: transparent;
  background-image: url("../../../img/stars0.svg");
  background-repeat: no-repeat;
  background-size:100%;
  background-position: center center;
  width: 120px;

  &.red {
    background-image: url("../../../img/stars0-red.svg");
  }

  &.stars1 {
    background-image: url("../../../img/stars1.svg");
  }
  &.stars2 {
    background-image: url("../../../img/stars2.svg");
  }
  &.stars3 {
    background-image: url("../../../img/stars3.svg");
  }
  &.stars4 {
    background-image: url("../../../img/stars4.svg");
  }
  &.stars5 {
    background-image: url("../../../img/stars5.svg");
  }
  &.stars1-red {
    background-image: url("../../../img/stars1-red.svg");
  }
  &.stars2-red {
    background-image: url("../../../img/stars2-red.svg");
  }
  &.stars3-red {
    background-image: url("../../../img/stars3-red.svg");
  }
  &.stars4-red {
    background-image: url("../../../img/stars4-red.svg");
  }
  &.stars5-red {
    background-image: url("../../../img/stars5-red.svg");
  }  
}

.review-title {
  margin-bottom: 6px;
}

ul.review-stars {
  text-align: center;
  font-size: rem-calc(16px);
  color: $rose;
  letter-spacing: 1px;
  margin-bottom: 30px;
  padding: 0;

  li {
    display: inline-block;
    margin-right: 10px;


    &:last-child {
      margin-right: 0;
    }
  }
}