.venue-title {
  @include breakpoint(large) {
    font-size: rem-calc(60px);
  }
}

.venue-address {
  color: $white;
  font-size: rem-calc(25px);
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 20px;
}


.searched-box {
  background-color: $lighter;
  padding: 30px;
  border-top: 1px solid $dark;
  border-bottom: 1px solid $dark;
  overflow: hidden;
  text-align: center;
  margin-top: 30px;

  .wrap {
    @include breakpoint(medium) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  a.button {
    max-width: 100px;
  }

  @include breakpoint(large) {
    padding: 15px;
    margin-top: 0;
  }

  @include breakpoint(1242px) {
    text-align: left;
  }

  p {
    font-size: rem-calc(24px);
    line-height: 26px;
    margin: 0 0 20px;
    text-transform: uppercase;

    @include breakpoint(large) {
      margin: 0 0 2px;
    }
  }

  ul {
    margin: 20px auto 0;
    padding: 0;

    @include breakpoint(large) {
      margin-top: 0;
    }

    li {
      list-style-type: none;

      @include breakpoint(large) {
        display: inline-block;
        margin: 0 10px 0 0;
      }

      span {
        color: $rose;
        font-weight: bold;
      }
    }
  }

  .button {
    margin: 0 auto 0;

    @include breakpoint(1242px) {
      float: right;
      margin-top: 0;
      width: 100%;
      min-width: inherit;
    }
  }
}

.space-wrap {
  @include breakpoint(large) {
    margin-bottom: 0;
  }
}

.venue-image {
  .image {
    height: 303px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    .enlarge-icon {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      top: 0;
      img {
        position: absolute;
        bottom: 10px;
        right: 10px;
      }
    }

    .video-slide {
      background: url("../../../img/icon-video-play.svg") center center no-repeat;
      background-size: 65px;
    }
  }
  .overlay {
    overflow: hidden;

    @include breakpoint(medium) {
      height: 60px;
    }
    @include breakpoint(large) {
      height: 30px;
    }

    .status {
      font-size: rem-calc(12px);

      @include breakpoint(medium) {
        display: block;
        text-align: left;
      }
      @include breakpoint(large) {
        display: inline-block;
        font-size: rem-calc(10px);
      }
      @include breakpoint(xlarge) {
        font-size: inherit;
      }
    }

    .review {
      @include breakpoint(medium) {
        float: none;
        height: 32px;
        margin-left: 4px;
      }
      @include breakpoint(large) {
        float: right;
        margin-left: 0;
      }
    }
  }
}

.title-underline {
  margin-top: 6px;
  border-bottom: 1px solid $light;
  padding-bottom: 10px;
  text-transform: capitalize;
  font-weight: 600;
  color: $vb-black;
}

.venue-detail {
  .title-underline {
    font-size: rem-calc(26px);
    line-height: 26px;
  }

  p {
    font-size: rem-calc(16px);
  }

  .note {
    color: $rose;
    font-style: italic;
    font-weight: 600;

    &.green {
      color: $offset;
    }

  }

}

.venue-action {
  background-color: $lighter;
  padding: 16px;
  max-width: 400px;
  margin: 0 auto;

  table tbody tr td {
    height: 44px;
    padding: 0;

    &.empty {
      height: 22px;
    }

    &.split-left {
      padding-right: 0.46875rem;
    }
    &.split-right {
      padding-left: 0.46875rem;
    }
  }

  ul.venue-info-list {
    margin: 0 auto 34px;
    padding: 0;
    text-align: center;

    li {
      background-position: center left;
      background-repeat: no-repeat;
      background-size: 32px 32px;      
      padding-left: 74px;
      list-style-type: none;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-bottom: 8px;
      text-align: left;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .cost {
      background-image: url("../../../img/icon-costs-black.svg");
    }
    .capacity {
      background-image: url("../../../img/icon-capacity-black.svg");
    }
    .area {
      background-image: url("../../../img/icon-area-black.svg");
    }
    .cabaret {
      background-image: url("../../../img/icon-seating-cabaret-black.svg");
    }
    .standing {
      background-image: url("../../../img/icon-seating-standing-black.svg");
    }
    .dining,
    .dinner {
      background-image: url("../../../img/icon-seating-dining-black.svg");
    }
    .theater {
      background-image: url("../../../img/icon-seating-theatre-black.svg");
    }
    .dinnerdance {
      background-image: url("../../../img/icon-seating-dinnerdance-black.svg");
    }
    .boardroom {
      background-image: url("../../../img/icon-seating-boardroom-black.svg");
    }
    .ushape {
      background-image: url("../../../img/icon-seating-ushape-black.svg");
    }
    .classroom {
      background-image: url("../../../img/icon-seating-classroom-black.svg");
    }
  }

  .button {
    min-width: 100%;
    margin: 0;
  }

  .space-held {
    color: $rose;
    text-transform: uppercase;
    text-align: center;
    border-top: 1px solid $rose;
    border-bottom: 1px solid $rose;
    font-size: rem-calc(21px);
    line-height: 36px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.venue-detail-tabs {
  .accordion-item a.accordion-title, .tabs-title a {
    color: $white;
    font-size: rem-calc(14px);
    text-align: center;
    background-color: $offset;
    transition: .5s all;

    @include breakpoint(large) {
      font-size: rem-calc(16px);
    }

    &:before {
      content: '';
    }
    &:focus, &:hover {
      background-color: $jet-stream;
    }
  }

  .accordion-item a.accordion-title {
    margin-bottom: 1px;
  }

  .venue-description {
    border-bottom: 1px solid $light;
    padding-bottom: 10px;
    margin-bottom: 20px;

    p {
      font-size: rem-calc(16px);

      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  .tabs-title.is-active {
    a {
      position: relative;
      background-color: $jet-stream;
      color: $vb-black;
      font-weight: 600;

      &:after {
        content: '';
        position: absolute;
        bottom: -10px;
        margin: auto;
        left: 0;
        right: 0;
        height: 0;
        width: 0;
        border-top: 10px solid $jet-stream;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;

        @include breakpoint(large) {
          bottom: -32px;
          height: 20px;
          width: 20px;
          border-top: 32px solid $jet-stream;
          border-left: 32px solid transparent;
          border-right: 32px solid transparent;
        }
      }

      &:focus, &:hover {
        background-color: $jet-stream;
      }
    }
  }

  .accordion-content, .tabs-panel {
    background-color: $mystic;
  }

  .tabs-title {
    width: calc(100% / 4);
    padding-right: 1px;

    &:last-child {
      padding-right: 0;
    }
  }

  .tabs-panel {
    padding: 54px 0;
  }

  #panel1a, #panel1b, #panel1c, #panel1d  {
    @include breakpoint(large) {
      padding: 54px 0 12px;
    }
  }

  .key-title {
    color: $vb-black;
    font-size: rem-calc(20px);
    font-weight: 600;
    line-height: 17px;
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: 26px 26px;    
    padding-top: 14px;
    padding-left: 36px;
    margin-top: 0;
  }

  .icon-suitable {
    background-image: url("../../../img/icon-suitable-black.svg");
  }
  .icon-info {
    background-image: url("../../../img/icon-info-black.svg");
  }
  .icon-licensing {
    background-image: url("../../../img/icon-licensing-black.svg");
    padding-left: 46px;
  }
  .icon-capacity {
    background-image: url("../../../img/icon-capacity-black.svg");
    padding-left: 40px;
  }
  .icon-availiability {
    background-image: url("../../../img/icon-availiability-black.svg");
  }
  .icon-costs {
    background-image: url("../../../img/icon-costs-black.svg");
  }

  ul.key-list {
    color: $vb-black;
    font-size: rem-calc(14px);
    margin-bottom: 20px;

    @include breakpoint(medium) {
      margin-bottom: 0;
    }

    li {
      margin-bottom: 4px;

      &.ex-item {
        display: none;
        text-decoration: line-through;
        color: $offset;

      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .view-more {
    position: relative;
    display: block;
    margin: 10px auto 0;
    text-align: center;
    border-top: 1px solid $lighter;
    padding-top: 12px;
    color: #78A4A5;
    font-size: rem-calc(14px);
    max-width: 96%;

    div {
      max-width:108px;
      margin: 0 auto;
      overflow: hidden;
    }

    &.active {
      span.icon.add {
        &:after {
          opacity: 0;
        }
      }
    }

    span {
      float: right;

      &.icon {
        width: 17px;
        height: 17px;
        float: left;
      }

      &.icon.add {
        transition: all 0.3s;
        top: 2px;
        left: 2px;
        border-color: $offset;
      }

      &.icon.add::after {
        top: 7px;
        left: 3px;
        height: 1px;
        background: $offset;
        width: calc(100% - 6px);
      }

      &.icon.add::before {
        left: 7px;
        top: 3px;
        width: 1px;
        background: $offset;
        height: calc(100% - 6px);
      }

      &.icon.add::before, span.icon.add::after {
        background-color: $offset;
      }
    }
  }
}

.venue-offer {
  .title-underline {
    font-size: rem-calc(20px);
    margin-bottom: 6px;
  }

  p {
    color: $vb-black;
    white-space: pre-wrap;
  }

  img {
    margin-bottom: 20px;
  }

  .available {
    margin-top: 0;
  }

  .button {
    margin: 4px 0 0;
  }
}

/*Floorplans*/
.venue-detail-tabs {
  #panel3 {
    padding-left: 15px;
    padding-right: 15px;
  }
}


.venue-floorplans {
  a {
    display: block;
    text-align: center;

    p {
      color: $vb-black;
      font-size: rem-calc(16px);
      font-weight: 600;
      line-height: 24px;
      margin-top: 8px;
    }

    &:hover {
      img {
        opacity: 0.8;
      }
    }
  }
}

.tour-title {
  text-align: center;
  font-size: rem-calc(15px);
  margin: 0;
}

  .tour.tour-360 {
    margin-bottom: 30px;

    @include breakpoint(medium) {
      margin-bottom: 0;
    }
  }

.tour {
  height: 260px;
  width: 100%;
  max-width: 622px;
  display: block;
  margin: 0 auto;
  margin-top: 20px;

  @include breakpoint(medium) {
    height: 320px;
    margin-top: 0;
    position: relative;
    left: 0;
    right: 0;
  }
}

#venue-map {
  width: 100%;
  height: 600px;
}

.venue-suppliers-tabs {
  .tabs-title {
    width: calc(20% - 1px);
  }

  .tabs-title.is-active {
    padding-right: 0;
    padding-left: 0;
  }

  .tabs-title.is-active a {
    background-color: $white;
    color: $rose;
    border: 1px solid $rose;

    &:focus, &:hover {
      background-color: $white;
    }
  }

  .accordion-item a.accordion-title, .tabs-title a {
    background-color: $rose;
    color: $white;
    border: 1px solid $rose;
    font-size: rem-calc(13px);
    padding-left: 2px;
    padding-right: 2px;

    &:after {
      display: none;
    }

    &:focus, &:hover {
      background-color: $white;
      color: $rose;
    }
  }

  .tabs-panel {
    background-color: transparent;
    padding-top: 38px;
    padding-bottom: 38px;
  }

  .suppliers-intro {
    color: $offset;
    font-size: rem-calc(18px);
    font-weight: 600;
    line-height: 34px;

    @include breakpoint(medium) {
      font-size: rem-calc(18px);
    }

    @include breakpoint(medium) {
      font-size: rem-calc(20px);
    }
  }

  ul.venue-suppliers-info{
    padding: 0;
    margin-top: 0px;
    margin-bottom: 30px;
    font-size: rem-calc(14px);

    @include breakpoint(medium) {
      margin-top: 24px;
    }

    li {
      list-style-type: none;
      margin-bottom: 5px;

      &.supplier {
        font-size: rem-calc(18px);
        color: $offset;
        font-weight: 600;
      }
      &.supplier-highlight {
        font-size: rem-calc(18px);
        font-weight: 600;
        background-color: $offset;
        padding: 2px 6px;
        color: $white;
        margin-bottom: 8px;
      }

      span {
        font-weight: 600;
      }

      a {
        color: inherit;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}


.venue-slideshow {
  ul {
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      padding: 0;
      min-height: 400px;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      background-color: $vb-black;
      list-style-type: none;
      position: relative;

      &.slick-slide {
        @include flexbox();
        @include flex-direction(row);
        @include align-items(center);
      }

      &.slick-slide iframe {
        width: 100%;
      }
    }
  }
}


.venue-detail-search {
  overflow: inherit;

  form {
    @include breakpoint(1300) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;

      > div,
      > select,
      > input,
      > .date-container,
      > .search-ddr,
      > input[type="submit"] {
        width: calc((100% / 4) - 15px);
        margin-right: 15px !important;
        max-width: 1300px;

        select,
        input {
          width: 100%;
          max-width: 1300px;
        }
      }

      > .search-ddr {
        top: 5px;
      }

    }
  }

  .search-note {
    margin-top: 20px;
    display: inline-block;
    @include breakpoint(1300) {
      margin-top: 0;
    }
  }

  .awesomplete {
    @include breakpoint(1300) {
      display: inline-block;
    }

    input:not([type="submit"]) {
      @include breakpoint(1300) {
        width: 100%;
      }
    }

    ul li {
      width: 100%;
      text-align: left;

      @include breakpoint(1300) {
      }
    }
  }

  .event-scale, .date-container {
    display: inline;
  }

  .date-container {
    display: inline-block; 
    width: 100%;

    .Zebra_DatePicker_Icon_Wrapper {
      width: 100%;
    }
  }

  .search-ddr {
    position: relative;
    display: inline-block;

    @include breakpoint(1300) {
      top: 38px;
    }    
  }

  input[type="checkbox"] + label span:not(.has-tip) {
    border: solid 1px #383838;
    margin-left: 0;
    margin-right: 6px;
  }

  input[type="checkbox"] + label {
    background-color: transparent;
    color: #383838;
    font-size: 13px;    
  }

  input[type="checkbox"]:checked + label span {
    margin-left: 0;
    margin-right: 6px;
  }

  select {
    background-position: 101% 3px;
  }

  select, textarea, input:not([type="submit"]) {
    font-size: 15px;
  }

  select, textarea, input, input[type="submit"], .date-container, .search-ddr {
    @include breakpoint(1300) {
      width: calc(100% / 7);
      max-width: 192px;
    }
  }

  .Zebra_DatePicker_Icon_Wrapper {
    input:not([type="submit"]) {
      width: 100%;
    }
  }

  ul.radiocheck-list {
    display: none;
  }

  input[type="submit"]  {
    padding-top: 9px;
    padding-bottom: 9px;
    margin: 4px auto 0;

    @include breakpoint(1300) {
      display: inline-block;
      min-width: inherit;
      max-width: 120px;      
    }
  }
}


