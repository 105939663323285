section.messages {
  .grid-x {
    position: relative;
  }

  & > h2 {
    display: inline-block;
    vertical-align: sub;
    margin-right: 20px;
  }

  form.search {
    display: inline-block;
    margin-left: 50px;
    float: right;
    @include breakpoint(medium) {
      min-width: 300px;
    }

    input {
      display: inline-block;
      max-width: 85%;
      margin-right: 10px;
      height: 35px;
    }

    img {
      display: inline-block;
      max-width: 25px;
      vertical-align: top;
      margin-top: 16px;
    }
  }

  .messages {
    padding: 10px 0 0;

    @include breakpoint(small down) {
      padding: 0;
      overflow: hidden;
    }

    > .grid-x.no-v-spacing {
      min-height: 295px;

      > .centre-text {
        margin-bottom: 38px;
      }

    }

    .load-more {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .message-types {
    border-bottom: 1px solid $light;
    margin-left: 35px;
    margin-right: 35px;
    text-align: left;

    .tabs-title > a {
      font-size: inherit;
      padding: inherit;      
    }

    .type-toggle {
      display: inline-block;
      padding: 7px 0;
      text-transform: uppercase;
      border-bottom: 3px solid transparent;
      transition: .3s all;

      a {
        color: $vb-black;
      }

      &.is-active {
        border-color: $offset;
        font-weight: 600;
      }

      &:hover {
        border-color: $offset;
      }
    }
  }

  .messages-feed {
    padding-left: 35px;
    padding-top: 25px;
    max-height: 630px;

    @include breakpoint(small down) {
      padding-left: 0;
      padding-top: 0;
      max-height: 450px;
    }
  }

  .message {
    text-align: left;
    background-repeat: no-repeat;
    background-size: 30px;
    background-position: 10px 10px;
    padding-left: 50px;
    margin: 10px 0;
    margin-right: 35px;
    padding-bottom: 20px;
    padding-top: 10px;
    border-radius: 10px;
    position: relative;
    transition: .3s all;
    cursor: pointer;

    @include breakpoint(small down) {
      margin: 0;
      padding: 10px 0 10px 10px;
      background-image: none !important;
      border-radius: 0;
      border-bottom: 1px solid $light;
      &.unread {
        background: $light-brand;
        border-left: 4px solid $brand;
      }
    }

    h3, h4, p {
      margin: 0;
    }

    p,
    h4 {
      padding-right: 15px;
    }

    .time {
      position: absolute;
      right: 35px;
      top: 10px;
      font-size: 0.7rem;
      @include breakpoint(small down) {
        right: 10px;
      }
    }
    &:hover {
      background-color: $lighter;
    }
    &.unread {
      background-image: url(../../../img/icon-vbselected.svg);
    }
    &.read {
      background-image: url(../../../img/icon-vbunselected.svg);
      h3,
      h4 {
        font-weight: normal;
      }
    }
    &.active {
      background-color: $lighter;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        top: 0;
        margin: auto;
        right: -20px;
        height: 20px;
        width: 20px;

        border-top: 10px solid transparent;
        border-left: 10px solid $lighter;
        border-bottom: 10px solid transparent;

        @include breakpoint(small down) {
          content: none;
        }
      }
    }
  }

  .message-thread {
    padding-right: 35px;
    padding-left: 25px;

    @include breakpoint(small down) {
      position: absolute;
      background: white;
      left: 100%;
      padding-right: 0;
      padding-left: 0;
      transition: .3s all;

      &.active {
        left: 0;
      }
      & > .wrapper {
        padding-right: 20px;
        padding-left: 15px;
        max-height: 240px;
        overflow: auto;
      }
    }
    .heading {
      border-bottom: 1px solid $light;
      position: relative;
      @include breakpoint(small down) {
        border: 0;
        background: $lighter;
        &:before {
          background-image: url(../../../img/icon-chevron.svg);
          background-repeat: no-repeat;
          background-position: left center;
          content: '';
          height: 100%;
          width: 20px;
          position: absolute;
          left: 0;
          top: 0;
          transform: rotate(180deg);
        }
      }
      h4 {
        margin: 0;
        font-weight: 600;
        display: inline-block;
        border-bottom: 3px solid $brand;
        padding: 10px 0;
        @include breakpoint(small down) {
          padding: 5px 0;
          padding-left: 30px;
          border: 0;
        }
      }
    }
    .chat-message {
      h4, p {
        margin: 0;
      }
      h4 { 

        font-weight: 600; 
        margin-top: 12px;
      }
      .time {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 0.7rem;
      }

      margin: 10px 0;
      padding: 10px;
      border-radius: 10px;
      max-width: 400px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        top: 0;
        margin: auto;
        left: -20px;
        height: 20px;
        width: 20px;

        border-top: 10px solid transparent;
        border-right: 10px solid $lighter;
        border-bottom: 10px solid transparent;
      }

      &.receive {
        background-color: $lighter;

      }
      &.sent {
        background-color: $offset;
        color: $white;
        margin-left: auto;
        &:after {
          left: auto;
          right: -20px;
          border-right: 0;
          border-left: 10px solid $offset;
        }
      }
    }
  }

  div.send-message {
    padding-top: 35px;
    @include breakpoint(small down) {
      padding-top: 0;
    }
  }
}
