.amchart{
  width: 100%;
  height: 360px;
  font-size: 11px;
}


.stats-block {
	background: $rose;
	border-radius: 8px;
	margin: 0 auto;
	width: 96%;
	padding: 20px;
	margin-bottom: 10px;
	margin-top: 10px;

	p {
		color: $white;
		text-align: center;
		margin: 0;
		font-size: rem-calc(16px);

		span {
			display: block;
			font-size: rem-calc(36px);
			font-weight: 600;
		}
	}
}