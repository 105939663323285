#cookie-bar {
	background: $rose;
	line-height: 24px;
	color: #eeeeee;
	padding: 20px 0;
	font-size: 0.8em;
}

#cookie-bar.fixed {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 999999
}

#cookie-bar.fixed.bottom {
	bottom: 0;
	top: auto;
}

#cookie-bar .grid-x {
	margin: 0;
}

#cookie-bar img {
	height: 50px;
	width: 50px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 20px;
}

#cookie-bar p {
	width: 80%;
  padding: 0;
	margin: 0;
	font-size: rem-calc(14px);
  line-height: rem-calc(15px);
	font-weight: 300;
	display: inline-block;
	vertical-align: middle;
}

#cookie-bar a {
	display: block;
	margin: 0 5px !important;
	min-width: 196px;
	width: calc(50% - 11px);
	display: inline-block;

	@include breakpoint(1270px down) {
		margin: 5px !important;
	}

	&.cb-enable {
		border: 1px solid $white;
	}

	&.cb-disable {
		border: 1px solid $white;
		color: $white;

		&:hover {
			background: $white;
			color: $rose;
		}
  }

  &.cb-button {
		border: 1px solid $white;
		color: $white;

		&:hover {
			background: $white;
			color: $rose;
		}
	}
}

.betanotice {
	font-size:14px;
	padding-top:5px;
	border-top: 1px solid white;
	margin-top: 7px;
	text-align: center;
}
