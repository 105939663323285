ul.calendar-view-control {
  li {
    display:inline-block;
    width: calc(33% - 2px);
    height: 29px;
    margin-right: 3px;

    &:last-child {
      margin-right: 0;
    }

    a {
      width: 100%;
      height: 100%;
      display: block;
      padding: 5px;
      text-align: center;
      color: $white;
      text-transform: uppercase;
      font-size: rem-calc(12px);
      line-height: rem-calc(16px);
      font-weight: 500;
      background-color: $rose;
      position: relative;

      &.is-active {
        background-color: $offset;

        &::after {
          content: '';
          position: absolute;
          bottom: -10px;
          margin: auto;
          left: 0;
          right: 0;
          height: 0;
          width: 0;
          border-top: 10px solid $offset;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
        }

      }
    }
  }
}

.otxcalendar-controls.btn-group {
  > a {
    float: left;
    display: block;
    width: 20px;
    height: 10px;
    margin-top: 15px;
    background-image: url(../../../img/icon-dropdown.svg);
    background-repeat: no-repeat;
    background-size: cover;
    &:first-child {
      transform: rotate(90deg);
    }
    &:last-child {
      transform: rotate(270deg);
    }

  }
}

.otxcalendar-title {
  color: $vb-black;
  font-size: rem-calc(18px);
  font-weight: 600;
  float: left;
  width: auto;
  display: block;
  margin: 7px 10px 0 10px;
}

.fc-unthemed th, .fc-unthemed td, .fc-unthemed thead, .fc-unthemed tbody, .fc-unthemed .fc-divider, .fc-unthemed .fc-row, .fc-unthemed .fc-content, .fc-unthemed .fc-popover, .fc-unthemed .fc-list-view, .fc-unthemed .fc-list-heading td {
  //border-color: transparent;
}


.fc-view-container {
  > .fc-view {
    > table {
      > thead.fc-head {
        > tr {
          background: transparent;
          > td.fc-head-container {
            border-top:none;
            border-left:none;
            border-right:none;
            > div.fc-row {
              border-right: none;
              > table {
                > thead {
                  > tr {
                    background: transparent;

                    > th {
                      border: none;
                      color: $dark;
                      text-transform: none;
                      font-size: rem-calc(12px);
                      font-weight: 600;
                    }
                  }
                }
              }
            }
          }
        }
      }
      > tbody.fc-body {
        background: $mystic;
        > tr {
          > td.fc-widget-content {
            div.fc-day-grid.fc-unselectable,
            div.fc-scroller.fc-time-grid-container {
              div.fc-bg {
                > table {
                  > tbody {
                    > tr {
                      > td {
                        &.fc-today {
                          background:$geyser;
                        }
                        border-color: $white;
                        border-width: 2px;
                      }
                    }
                  }
                }
              }
              div.fc-content-skeleton {
                > table {
                  > thead {
                    > tr {
                      background: transparent;
                      border-bottom: none;

                      td.fc-today {
                        background: $rose;
                        text-align: center;
                        > span {
                          float: none;
                          color: $white;
                        }
                      }
                    }
                  }
                }
              }
              div.fc-slats {
                > table {
                  > tbody {
                    > tr {
                      border-bottom-color: transparent;
                      &:not(.fc-minor) {
                        > td.fc-widget-content {
                          border-top-color: $white;
                        }
                      }
                      > td {
                        //border: none;
                        //background: $mystic;
                        &:first-child {
                          //background: $white;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


.fc-head-container.fc-widget-header {
  thead {
    background: transparent;
  }
}

.fc-day-header {
  background: transparent;
}


.otxcalendar-event-form {
  .btn-stacker-inline {
    padding: 0;
  }
}


.fc-view-container *, .fc-view-container *:before, .fc-view-container *:after {
  text-transform: uppercase;
}