.faqs {
  padding: 0;

  li {
    list-style-type: none;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    div {
      @include breakpoint(medium) {
        max-width: 65%;
      }
    }
  }

  .title {
    text-transform: uppercase;
    font-size: rem-calc(22px);
    margin-bottom: 18px;

    @include breakpoint(small down) {
      font-size: rem-calc(14px);
    }
    letter-spacing: 1.5px;
    border-bottom: 1px solid $dark;
  }

  p {
    margin: 0;
  }
}
