.holding-policy {
  width: 200px;
  padding: 0;
  margin: 0 auto 14px;
  line-height: 6px;  

  li {
    list-style-type: none;
    display: inline-block;

    &:first-child {
      margin-right: 44px;
    }
    label {
      font-size: 1.125rem;
    }
  }
}


.holding-policy-day {
  margin: 0 0 18px;
  padding: 0;
  font-size: 0.75rem;
  overflow: hidden;

  li {
    width: 50%;
    list-style-type: none;

    &:first-child {
      float: left;
      padding-right: 4px;
    }
    &:last-child {
      float: right;
      padding-left: 4px;      
    }    
  }

}

.holding-note {
  font-size: 0.75rem;
  text-align: center;
}


.frm-overview {
  .button {
    display: block;
    margin: 0 auto;
    max-width: 300px;
  }
}


.venue-edit {

  .title-alt {
    margin: 0 auto 16px;
  }

}


.myimages {
  a {
    display: block;
    width: 100%;
  }
}


.desktop {
  text-transform: uppercase;
  font-size: 0.8rem;
  text-align: center;
  width: 68%;
  margin: 0 auto;  
}