.fake-tabs {
  padding-top: 25px;

  & > div.grid-padding-x:first-child {
    display: none;
  }

  label[for="url"] {
    font-size: 22px;
    width: 75px;
    float: left;
    margin-top: 15px;

  }

  select#url {
    width: calc(100% - 75px);
    float: left;
    margin-top: 0px;
  }

  @include breakpoint(large) {
    // usual banner-overlay has margin-top: -248px
    margin-top: -136px;
    height: 40px;

    & > div.grid-padding-x:first-child {
      display: flex;
      margin-top: -72px;
      height: 50px;
      width: calc(100% + 0.96875rem);
      max-width: calc(100% + 0.96875rem);
      margin-left: -0.46875rem;
      margin-right: -0.46875rem;

      > .cell:first-child {
        padding-left: 0;
      }

      > .cell:last-child {
        padding-right: 0;
      }

      a {
        padding: 15px;
        color: $vb-black;
        text-align: center;
        background: rgba(255, 255, 255, 0.75);
        height: 100%;
        display: block;

        &.active {
          background: white;
        }
      }
    }

    label[for="url"],
    select#url {
      display: none;
    }

  }
}

.highlight.image {
  .home-form {
    margin: 0 auto;
  }
}

.hiw-wrap {
  position: relative;
  padding-bottom: 38px;
  & > a {
    position: relative;
    bottom: 0;
    margin: 0;
    left: 0;
    right: 0;
    margin: 0 auto;

    @include breakpoint(1480px) {
      position: absolute;
      margin: 0 auto!important;      
    }
  }
}

figure img {
  max-width: 150px;

  @include breakpoint(large) {
    max-width: inherit;
    width: 100%;
  }
}
