.home {
  .logo-main {
    display: none;
  }
  .home-logo {
    display: block;
  }
  .banner-overlay {
    z-index: inherit;
    padding-top: 12px;

    .title-small {
      margin: 20px 0;
    }

    @include breakpoint(large) {
      margin-top: -180px;

      &:after{
        content: '';
        position: absolute;
        bottom: 10px;
        width: 100%;
        height: 1px;
        background-color: $light;
      }
    }
  }
}

.home-banner {
  position: relative;
  top: 0;
  padding: 30px 0 100px;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;

  &:after {
    display: block;
    content: '';
    background: rgba(0, 0, 0, 0.50);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  @include breakpoint(large) {
    top: -96px;
    padding: 120px 0;
  }

  .grid-container {
    position: relative;
    z-index: 5;
    max-width: 86%;

    @include breakpoint(large) {
      max-width: 1240px;
    }

    .grid-x {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .title {
    font-family: 'nexa_lightregular';
    text-transform: none;
    color: $white;
    font-size: rem-calc(30px);
    line-height: 34px;
    font-weight: normal;
    margin: 0 0 30px;
    max-width: 770px;

    @include breakpoint(medium) {
      font-size: rem-calc(40px);
      line-height: 43px;
    }

    @include breakpoint(large) {
      font-size: rem-calc(50px);
      line-height: 60px;
    }
  }
}


.home-form {
  max-width: 411px;

  [type='text'] {
    display: inline-block;
  }

  input, select {
    color: $white;
    border-color: $white;
    background-color: rgba(66, 66, 66, 0.6);
    margin: 0 0 5px 0;
    text-transform: uppercase;
    font-size: rem-calc(16px);
    font-weight: bold;
    line-height: 16px;
    letter-spacing: 1.3px;
    height: 38px;

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $white;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: $white;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: $white;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: $white;
    }
    &[type='text']:focus {
      background-color: rgba(66, 66, 66, 0.6);
    }
  }

  .search-ddr {
    margin-top: -10px;
  }

  input[type="checkbox"] {
    & + label {
      background-color: transparent;
      display: block;
      width: auto;
      float: left;
      margin: 0 10px 14px -5px;
      border-radius: 4px;
      text-transform: none;
      font-size: rem-calc(15);
      font-weight: 600;
      line-height: 24px;
      color: white;
      padding: 5px 10px;
      cursor: pointer;

      //span:not(.top),
      span:not(.has-tip) {
        background-color: transparent;
        border: solid 1px white;
        transform: rotate(45deg);
        width: 12px;
        height: 12px;
        margin: 0;
        border-radius: 2px;
        margin-left: 4px;
        margin-right: 8px;

        &::after {
          transition: all 300ms;
          opacity: 0;
          content: '';
          position: absolute;
          top: 0px;
          left: 0px;
          border-radius: 2px;
          width: 10px;
          height: 10px;
          background-color: $white;
          border-radius: 2px;
        }
      }
    }

    &:checked {
      & + label {
        span {
          border: solid 1px $vb-black;
          &::after {
            opacity: 1;
          }
        }
      }
    }
  }

  select {
    background-image: url(../../../img/icon-dropdown-white.svg);
    background-position: 102% 5px;
    background-repeat: no-repeat;
    background-size: 20px;

    &:focus {
      background-color: rgba(66, 66, 66, 0.6);
    }
  }

  .Zebra_DatePicker_Icon_Wrapper {
    width: 100%;
    max-width: 100%;

  }

  .radiocheck-list {
    position: relative;
    margin: 4px 0 10px 0;

    @include breakpoint(large) {
      margin-bottom: 18px;
    }

    li {
      display: inline-block;

      label {
        color: $white;
      }
    }

    input[type="radio"] + label span, input[type="checkbox"] + label span {
      width: 14px;
      height: 14px;
    }
    input[type="radio"] + label span, input[type="checkbox"] + label span {
        background-color: transparent;
        border: 1px solid $white;
    }
    input[type="radio"]:checked + label span:after, input[type="checkbox"]:checked + label span:after {
      top: 3px;
      left: 3px;
      width: 6px;
      height: 6px;
      background-color: $white;
    }
  }

  input[type="submit"] {
    background-color: $white;
    color: #424242;
    height: 34px;
    width: 100%;
    max-width: 419px;
    border-radius: 2px;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1.14px;
    line-height: 22px;
    text-align: center;
    display: block;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
      border-color: $white;
    }
  }
}

.home-featured-venue, .home-featured-venue, .home-content, .home-slider, .home-inspiration {
  text-align: center;
}

.home-featured-venue {
  @include breakpoint(medium) {
    text-align: left;
    padding-bottom: 60px;
  }

  .button.small {
    min-width: 210px;

    @include breakpoint(medium) {
      margin: 0;
    }
  }

  .large-offset-1 {
    .title {
      margin-top: 30px;

      @include breakpoint(medium) {
        margin-top: 56px;
      }
    }
  }
}

.home-content {
  background-color: $mystic;
  padding-bottom: 16px;

  p {
    max-width: 900px;
    margin: 1em auto;
  }
  .grid-x {
    margin-top: 0;
    margin-bottom: 0;
  }

  .title-logo {
    text-align: center;
  }
}

.home-recommended-venue {
  text-align: left;

  @include breakpoint(large) {
    padding-top: 44px;
    margin-bottom: 20px;
  }

  @include breakpoint(medium) {
    text-align: center;
    background-color: transparent;
  }

  .grid-x {
    &:first-of-type {
      margin-top: 0;
      margin-bottom: 0;
    }
    &:last-of-type {
      margin-top: 0;
    }
  }

  .title {
    margin-top: 0;
  }

  p {
    margin-bottom: 26px;
  }

  .img-link {
    margin-bottom: 15px;

    p {
      color: $vb-black;
      text-transform: uppercase;
      margin: 15px 0 0;
    }
  }

  .recommended-image {
    background-repeat: no-repeat;
    background-size: cover;
    height: 120px;

    @include breakpoint(medium) {
      height: 160px;
    }

    @include breakpoint(large) {
      height: 260px;
    }
  }

}


.home-slider {
  overflow: hidden;
  margin-bottom: 100px;

  @include breakpoint(large) {
    max-width: none;
  }

  .grid-x {
    margin-top: 0;
    margin-bottom: 0;
  }

  .title {
    margin-bottom: 22px;
  }

  .slick-arrow {
    height: 100%;
    width: 30px;
    background-color: rgba(0, 0, 0, .5);
    right: 6px;
    background-size: 14px;
  }

    ul.slick {
      margin: 0;
      padding: 0;

      li {
        position: relative;
        list-style-type: none;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 0 7.5px;

        a {
          background-position: center;
          background-size: cover;
          @include flexbox();
          @include align-items(center);
          @include justify-content(center);
          width: 100%;
          height: 208px;

          p {
            margin: 0;
            color: $white;
            font-size: rem-calc(20px);
            font-weight: bold;
            letter-spacing: 1px;
            line-height: 24px;
            text-align: center;
            text-transform: uppercase;
          }
        }
      }
    }
  }


.home-inspiration {
  max-width: 380px;
  margin-bottom: 40px;

  @include breakpoint(medium) {
    max-width: 751px;
  }

  @include breakpoint(large) {
    max-width: $global-width;
    margin-bottom: 60px;
  }

  .grid-x {
    margin-bottom: 15px;
    margin-top: 0;
    margin-bottom: 0;

    .grid-x {
      margin-top: 0;
      margin-bottom: 15px;
    }
  }

  .home-blog {
    height: 100%;
    width: 100%;
    min-height: 380px;
    max-width: 380px;
    max-height: 380px;
    position: relative;
    margin-bottom: 15px;
    position: relative;
    display: block;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    @include breakpoint(medium) {
      height: inherit;
      min-height: 375px;
      max-width: none;
    }

    @include breakpoint(large) {
      max-width: none;
    }

    &:after {
      content: '';
      width:  100%;
      height:  100%;
      top: 0;
      left: 0;
      position: absolute;
      background-color: rgba(120, 164, 165, .9);
      z-index: 2;
    }

    p {
      z-index: 5;
    }

    .title {
      position: absolute;
      text-align: left;
      color: $white;
      top: 80px;
      left: 8%;
      right: 8%;
    }
    .title-small {
      position: absolute;
      text-align: left;
      color: $white;
      top: inherit;
      bottom: 4%;
      left: 4%;
    }
  }

  .instagram {
    display: block;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    color: transparent;
  }

  .instagram-small {
    height: 182px;
  }
  .instagram-wide {
    height: 182px;
  }
}



//Video Fill container
.home {
  .home-banner {
    overflow: hidden;
  }

  .video-item{
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
  }
}
