.booked-detail {
	position: relative;
	margin: 60px 0;
	padding: 16px;

    &:after, &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      border-top: 1px solid $vb-black;
      width: 66px;
      height: 1px;
    }

    &:after {
    	top: inherit;
    	bottom: -14px;
    }

    p {
      margin: 0 0 8px;
      font-size: rem-calc(24px);
    }
 
    .share-title {
      font-size: rem-calc(16px);
      text-transform: uppercase;
      margin-top: 24px;
      margin-bottom: 10px;
      color: $rose;
    }

    .venue {
      font-size: rem-calc(36px);

      span {
        font-weight: bold;
      }
    }
    .date {
      color: $dark;
      text-transform: uppercase;

      span {
        font-weight: bold;
      }      
    }
}

ul.booked-share {
  li {
    img {
      display: block;
      width: 34px;
    }
  }
}