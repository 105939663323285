body.event-planner {
  /*.reveal {
    overflow: visible;
  }*/

  label.clean,
  #builder-box .title-small{
    font-size: rem-calc(16px);
    color: $vb-black;
    margin-bottom: 7px;
    font-weight: 600;

    + .Zebra_DatePicker_Icon_Wrapper {
      padding-top:0 !important;
    }

    + input,
    + select {
      margin-top: 0;
    }
  }

  .options-copy {
    margin-bottom: 18px;
  }

  .estimate-wrap {
    margin-bottom: 30px;

    @include breakpoint(large) {
      margin-top: 9px;
    }

    label, p {
      display: inline;

      @include breakpoint(large) {
        display: block;
      }
    }
    label {
      margin-right: 4px;
      font-size: rem-calc(14px);      
    }
    p {
      font-size: rem-calc(20px);

      @include breakpoint(large) {
        font-size: rem-calc(25px);
      }
    }
  }

  p.price,
  #builder-box #total {
    color: $rose;
    font-size: rem-calc(25px);
    font-weight: 600;
    letter-spacing: 0.54px;
    margin: 7px 0;

    &.venue {
      margin-bottom: 57px;
    }
  }

  p.price {
    font-size: rem-calc(20px);

    @include breakpoint(large) {
      font-size: rem-calc(25px);
    }
  }

  input#start-date,
  input#end-date {
    + button.Zebra_DatePicker_Icon {
      background: url(../../../img/icon-calendar-red.svg) no-repeat center top;
      top: 17px !important;
    }
  }

  #builder-box {
    background: $white;
    padding: 15px;

    #total {
      font-size: rem-calc(45px);
      margin-bottom: 76px
    }

    button {
      margin: 0px auto;
      min-width: 190px;
      font-size: rem-calc(16px);
      @include breakpoint (large down) {
        min-width: 0;
      }
    }
  }

  div.dropdown-pane {
    background: $white;
    color: $vb-black;
    border:solid 13px $darker;
    width: 250px;
    padding: 10px;

    &.top::before {
      border: inset 1.5rem;
      position: absolute;
      content: '';
      border-color: $darker transparent transparent;
      transform: translate(-50%, 13px);
      display: block;
      width: 0;
      height: 0;
      border-bottom-width: 0;
      border-top-style: solid;
      bottom: -37px;
      left: 50%;
      transform: translateX(-50%);
    }

    > p {
      margin: 0;
    }
  }

}

.no-overflow {
  @include breakpoint(medium down) {
    overflow: hidden;
  }
}

#estimates {
  width: 100%;
  min-height: 800px;
  margin: 50px 0;

  .title-lower.large {
    line-height: 36px;
    + p {
      margin-bottom: 50px;
    }
  }

  .estimate {
    width: calc(100% - 25px);
    margin: 20px auto;
    border: solid 2px $offset;
    border-radius: 8px;
    padding: 5px 25px 20px;
    @include breakpoint(medium) {
      padding: 5px 5px 20px;
    }
    @include breakpoint(medium down) {
      margin-top: 20px;
    }

    div:nth-of-type(odd) {
      position: relative;

      &::before,
      &::after {
        content: "";
        position: absolute;
        display: block;
        top: 0em;
        width: 145px;
        background: $lighter;
        z-index: -1;
        height: 100%;

        @include breakpoint(large) {
          background: transparent;
          border: 64px solid $lighter;
        }

        @include breakpoint(xlarge) {
          border: 66px solid $lighter;
        }
      }

      &::before {
        left: -95px;
        border-right-width: 0;
        border-left-color: transparent;
      }

      &::after {
        right: -95px;
        border-left-width: 0;
        border-right-color: transparent;
      }
    }

    div {
      min-height: 128px;
      padding: 15px 5px;
      &:nth-of-type(odd) {
        background: $lighter;
      }
      @include breakpoint(xlarge) {
        min-height: 132px;
      }
    }

    .closer {
      float: right;
      color: $brand;
      text-transform: uppercase;
      font-size: 0.75rem;

      &.disabled {
        cursor: default;
        pointer-events: none;
      }
    }

    h4.title-small {
      word-break: break-all;
      color: $offset;
      font-size: rem-calc(18px);
      font-weight: 600;
      letter-spacing: 0.39px;
      margin-bottom: 0px;
      overflow: hidden;
      border-bottom: 1px solid $offset;
      padding-bottom: 3px;
    }

    p.price.center {
      color: $vb-black;
      font-size: rem-calc(40px);
      font-weight: 600;
      letter-spacing: 0.86px;
      position: relative;

      span {
        position: absolute;
        bottom: 10px;
        right: 0;
        font-size: rem-calc(14px);
        font-weight: 500;
        letter-spacing: 0.41px;
        color: $vb-black;

        @include breakpoint(large) {
          bottom: -5px;
          right: 50%;
          transform: translateX(50%);
        }
      }
    }

    p.title-small {
      font-weight: 600;
      font-size: rem-calc(18px);

      span.price {
        font-size: rem-calc(25px);
        color: $rose;
        float: right;
      }

      @include breakpoint(large only) {
        & + p {
          font-size: rem-calc(14px);
        }
      }

    }

    p.sub-info {
      margin-top: 4px;
    }

    p {
      font-size: rem-calc(16px);
    }
    
    ul {
      list-style: none;
      padding-left: 0px;

      li {
        min-height: 50px;

        a.button.mini,
        a.button.mini + p {
          float: right;
          width: 130px;
          min-width: 130px;
          margin: -5px 0 0 0;
        }
        a.button.mini + p {
          display: none;
          text-decoration: underline;
          text-transform: uppercase;
        }
      }
    }

    .button {
      min-width: 190px;
      font-size: rem-calc(14px);
    }
  }
}

#exampleModal1 {
  background: $white;

  li.accordion-item {
    border-bottom: solid 1px $light;
  }

  .accordion-title {
    font-size: rem-calc(20px);
    font-weight: 600;
    padding-right: 40px;

    > span {
      font-size: rem-calc(16px);
      font-weight: 500;
    }

    &::before {
      right: 18px;
    }
  }

  label span:first-child {
    width: 25px;
    height: 25px;
    padding: 3px;
    display: inline-block;
    float: left;
    background: $offset;
    border: none;
    margin-right: 0;

    > img {
      vertical-align: unset;
    }
  }

  .search-form label span:first-child + input[type="checkbox"] + label {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}
