/// Creates a wiggling animation.
/// @param {Number} $intensity [7deg] - Intensity of the wiggle. Can be any CSS angle unit.
/// @return {Map} A keyframes map that can be used with the `generate-keyframes()` mixin.
@function wiggle($intensity: 7deg) {
  $keyframes: (
    name: 'wiggle-#{$intensity}',
    (40, 50, 60): (transform: rotate($intensity)),
    (35, 45, 55, 65): (transform: rotate(-$intensity)),
    (0, 30, 70, 100): (transform: rotate(0)),
  );

  @return $keyframes;
}
