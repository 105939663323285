#map {
  width: 100%;
  height: 800px;
}

/* The location pointed to by the popup tip. */
.popup-tip-anchor {
  height: 0;
  position: absolute;
  /* The max width of the info window. */
  width: 290px;

  &.shower {
    .popup-bubble-anchor::after {
      display:block;
    }
    .popup-bubble-content {
      display: block;
    }
  }
}
/* The bubble is anchored above the tip. */
.popup-bubble-anchor {
  position: absolute;
  width: 100%;
  bottom: /* TIP_HEIGHT= */ 8px;
  left: 0;
  background-color: $white;



  &:hover {
    .popup-bubble-content {
      display: block !important;
    }
    &::after {
      display:block !important;
    }
  }

}
/* Draw the tip. */
.popup-bubble-anchor::after {
  display:none;
  content: "";
  position: absolute;
  top: -38px;
  left: 0;
  /* Center the tip horizontally. */
  transform: translate(-50%, 0);
  /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
  width: 0;
  height: 0;
  /* The tip is 30px high, and 36px wide. */
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-top: /* TIP_HEIGHT= */ 30px solid white;
}
/* The popup bubble itself. */
.popup-bubble-content {
  display:none;
  position: absolute;
  top: -38px !important;
  left: 0 !important;
  transform: translate(-50%, -100%);
  /* Style the info window. */
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  font-family: sans-serif;
  overflow-y: auto;
  max-height: 246px;
  box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.5);
  padding: 0;

  &:hover {
    display:block !important;
  }
}

.search-result.popup-bubble-content {
  padding-bottom: 10px;
  //Override Gmaps adding transform: scale(1) after filtering
  transform: translate(-50%, -100%) !important;

  .overlay .status {
    display: none;
  }

  .image {
    height: 150px;
    margin-bottom: 0;
  }
  .text {
    padding: 0 10px;

    .title-small {
      text-transform: uppercase;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin: 5px 0 0;
    }

    hr {
      display: none;
    }

    .location {
      margin-bottom: 10px;
    }

    .capacity {
      font-weight: 600;
      color: $vb-black;

      span {
        display: inline-block;
        text-indent: -9999999999px;
      }
      &:after {
        content: '';
        width: 25px;
        height: 25px;
        display: inline-block;
        background: url(../../../img/small-profile.svg);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-left: 5px;
        vertical-align: bottom;
      }
    }

      .cabaret {
        &:after {
          background: url(../../../img/icon-seating-cabaret.svg);
        }
      }
      .standing {
        &:after {
          background: url(../../../img/icon-seating-standing.svg);
        }
      }
      .dinning {
        &:after {
          background: url(../../../img/icon-seating-dining.svg);
        }
      }
      .theater {
        &:after {
          background: url(../../../img/icon-seating-theatre.svg);
        }
      }
      .dinnerdance {
        &:after {
          background: url(../../../img/icon-seating-dinnerdance.svg);
        }
      }
      .boardroom {
        &:after {
          background: url(../../../img/icon-seating-boardroom.svg);
        }
      }
      .ushape {
        &:after {
          background: url(../../../img/icon-seating-ushape.svg);
        }
      }
      .classroom {
        &:after {
          background: url(../../../img/icon-seating-classroom.svg);
          width: 24px;
        }
      }    

    .price {
      color: $vb-black;
      &:before {
        content: '';
        width: 25px;
        height: 25px;
        display: inline-block;
        background: url(../../../img/icon-small-coins.svg);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 5px;
        vertical-align: bottom;
      }
      span {
        display: inline-block;
        text-indent: -9999999999px;
        margin-left: -4px;
        color: $rose;

        &.numeric {
          text-indent: 0;
          margin-left: 0;
        }
      }
    }
  }
  .information-tooltip {
    .information { 
      display: none;
    }
  }  
}
