.suppliers-select {
	label {
		font-size: rem-calc(14px);
		font-weight: 500;
		margin-top: 10px;
		margin-bottom: 0;

		@include breakpoint(large) {
			font-size: rem-calc(16px);
			line-height: 50px;
			height: 50px;
		}
	}

	select {
		font-size: rem-calc(14px);

		@include breakpoint(large) {
			font-size: rem-calc(18px);
		}		
	}
}

.suppliers-intro-txt {
	font-size: rem-calc(14px);
	max-width: 90%;
	margin: 0 auto 20px;

	@include breakpoint(large) {
		font-size: rem-calc(18px);
	}
}

.supplier-delete {
  
	a.link {
    margin: 0 15px 0 0;
    
    span.icon.delete {
      margin: 2px 5px 0 0;
    }
	}

	.directory-title {
		color: $rose;
		border-bottom: 1px solid $lighter;
		padding-bottom: 8px;
		margin-bottom: 12px;		
	}

}

.admin-wrapper section .supplier-delete .grid-x {

	.title-delete {
		font-size: rem-calc(16px);
		font-weight: 500;
		line-height: 24px;
		margin: 0 0 8px;
		text-transform: uppercase;
	}
}

.admin-wrapper section {
	margin-bottom: 1rem;
}

ul.list-big {
	margin: 8px 0 0;
	padding: 0;

	li {
		list-style-type: none;
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}

		a {
			display: block;
			color: $rose;
			font-size: rem-calc(20px);
			font-weight: 500;
			line-height: 24px;
		}
	}
}