ul.serv-option-list {
	margin: 20px 0;
	padding: 0;
}

li[class^="serv-option-wrap-"], li[class*="serv-option-wrap-"] {
	display: none;
	list-style-type: none;
	margin: 0;

	.title-small {
		font-size: rem-calc(16px);
		line-height: 35px;		
	}

	&.active {
		display: block;
	}
}


.dashboard-header-title {
	@include breakpoint(large) {
		display: none;
	}
}


.dashboard-title {
	font-size: rem-calc(16px);
	text-transform: uppercase;
}



table tbody tr td .button.mini.delete-lead {
	margin-top: 6px;
}

/*Keep border radius on admin panels*/
.admin-wrapper .accordion-item:last-child > :last-child {
	border-radius: 4px;
}