.edit-venue {
  .your-details {
    margin-top: 10px;
  }

  p.title-small,
  label.title-small {
    color: $vb-black;

  }

  input[type="submit"]:last-child {
    margin-bottom: 25px;
  }

  .accordion .accordion-content {
    background-color: transparent;
  }

  .accordion .accordion-title {
    font-size: rem-calc(20px);
    z-index: 50;
    line-height: rem-calc(22px);
    background-color: transparent;
  }
}

ul.accordion.medium-down,
ul.accordion.dashboard-header-mobile,
ul.spaces.accordion {
  border-radius: 4px;

  > li {

    &:last-child:not(.is-active) {
      > .accordion-title {
        border-bottom: none;
      }
    }

    > .accordion-title {
      position: relative;

      &:hover,
      &:focus,
      &.is-active {
        background-color: transparent;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        background-color: $rose;
        opacity: 1;
      }

      &::before {
        width: 3px;
        height: 20px;
        right: 23px;
        top: 27px;
        transition: all 0.5s ease-in-out;
        transform: rotate(-90deg);
      }

      &::after {
        width: 20px;
        height: 3px;
        right: 15px;
        top: 28px;
        transition: all 0.5s ease-in-out;
        transform: rotate(-90deg);
      }
    }

    &.is-active {
      > .accordion-title {
        &::before {
          width: 3px;
          height: 20px;
          right: 23px;
          top: 27px;
          transition: all 0.5s ease-in-out;
          transform: rotate(90deg);
        }

        &::after {
          width: 20px;
          height: 3px;
          right: 15px;
          top: 28px;
          transition: all 0.5s ease-in-out;
          transform: rotate(90deg);
          opacity: 0;
        }
      }
    }
  }
}


// thanks to foundation this element is referenced by .spaces.accordion at medium and below,
// but by .spaces.tabs at large and up, so media queries are of limited use
ul.spaces.accordion {
  background: transparent;

  > .accordion-item {
    background: white;
    border-radius: 4px;

  }

  .accordion-content {
    .accordion .accordion-item .accordion-title {
      display:none;
    }

  }
}

ul.spaces.tabs {
  li.tabs-title {
    > a.title-small {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

ul.accordion.medium-down {
  @include breakpoint(large) {
    background: transparent;
    > li {

      > a {
        display:none;
      }

      .accordion-content {
        background: transparent;
        display: block !important;
        border-bottom: none;
      }
    }
  }
}




label[for^="venue_holding_policy"] {
  margin-top: 25px;
  margin-bottom: 25px;
  width: 40%;
  font-size: rem-calc(16px);

  + p.small {
    color: $dark;
  }
}


label[for="venue_holding_policy_days"] {
  font-size: rem-calc(16);
  font-weight: 500;
  text-transform: none;
  float: left;
  width: 50%;

  @include breakpoint(large) {
    width: auto;
  }
}

select#venue_holding_policy_days {
  width: 50%;
  margin-top:30px;
  max-width: 180px;
  float: right;
  @include breakpoint(large) {
    margin-top:15px;
  }
}

@include breakpoint(medium down) {
  label.title-small {
    margin-bottom:0;
  }
}

.venue-spaces {
  .accordion.spaces {
    li:last-child {
      > a#space-add {
        @extend .button;
        position: relative;

        span {
          position: absolute;
          height: 20px;
          width: 20px;
          left: 92px;
          top: 18px;

          &:before {
            border-bottom-width: 2px;
            border-bottom-style: solid;
            border-left-width: 2px;
            border-left-style: solid;
            left: 9px;
            top: 6px;
          }
          &:after {
            border-top-width: 2px;
            border-top-style: solid;
            border-right-width: 2px;
            border-right-style: solid;
            left: 6px;
            top: 9px;
          }        
          &:before, &:after {
              content: "";
              position: absolute;
              height: 5px;
              width: 5px;
          }
        }
      }
    }
  }

  .tabs.spaces {
    background: $light;


    li {
      list-style: none;
      background: $lighter;
      margin: 0 2px 2px 0;
      min-width: 200px;
      text-align: center;

      > a {
        text-transform: uppercase;
        color: $darker;
        font-size: rem-calc(16);
        font-weight: 500;
      }

      &:first-child {
        margin-left: 0;
      }

      &.is-active {
        background: $white;

        > a {
          color: $vb-black;
        }
      }

      &:not(.tabs-title) {
        float: left;

        > a {
          display: block;
          padding: 1.25rem 1.5rem 1.25rem 40px;
          line-height: 1;
          color: $rose;
          position: relative;

          &::before,
          &::after {
            content: "";
            position: absolute;
            background: $rose;
          }

          &::before {
            left: 40px;
            top: 18px;
            width: 2px;
            height: 20px;
          }

          &::after {
            top: 27px;
            left: 31px;
            height: 2px;
            width: 20px;
          }
        }
      }
    }
  }

  .tabs-panel {
    padding: 0;
    
    @include breakpoint(large){
      padding: 1rem 50px;
    }

    & > div:first-child > div:first-child > input.click2edit {
      text-align: center;
      color: $vb-black;
      font-size: rem-calc(24px);
      letter-spacing: 1px;
      line-height: 28px;
      height: 70px;
      width: 80%;
      margin: 10px auto;
    }

    p.small {
      color: $dark;
      margin-top: 0;
    }

    ul.accordion {
      > li.accordion-item {
          position: relative;

        .title-small {
          margin-top: 15px;
          margin-bottom: 0;
        }

        > a {
          padding-left: 0;
          border-bottom: solid 1px $dark;

          &:hover,
          &:focus {
            background-color: transparent;
          }

          &::before {
            content: '';
            background-image: url(../../../img/icon-dropdown.svg);
            width: 20px;
            height: 10px;
            background-size: contain;
            background-repeat: no-repeat;
            transition: all 0.3s;
          }
        }

        &.is-active {
          > a {
            &::before {
              transform: rotate(180deg);
            }
          }
        }

        /*.information-tooltip {
          top: 18px;
          right: auto;
          left: 225px;
        }*/

        .accordion-content {
          padding-left: 0px;
          padding-right: 0px;

          table.availability,
          table.seasons,
          table.offers {
            tr {

              th,
              td {
                padding: 9px 24px;
                border-right-width: 2px;
                border-bottom: solid 4px $white;
                border-top: solid 4px $white;
                border-right: solid 2px $lighter;

                &:last-child {
                  border-right: none;
                }

                &.no-padding {
                  padding: 0;
                }
                
                select.time-slot-select {
                  margin: 0;
                  border: none;
                  height: 42px;
                  color: $vb-black;
                  background-size: 21px;
                  background-color: $mystic;
                  color: $vb-black;
                  font-size: rem-calc(13px);
                  font-weight: 600;
                  text-transform: uppercase;

                  &:focus {
                    box-shadow: none;
                    border-radius: 0;
                    outline: 1px solid $dark; 
                  }

                  option {
                  font-size: rem-calc(15px);

                  }
                }
              }

              th {
                text-align: center;
              }

              td {
                background: $mystic;

                input[type="text"],
                input[type="number"],
                textarea {
                  margin: 0;
                  color: $vb-black;
                  font-size: rem-calc(13px);
                  font-weight: 600;

                  &.click2edit {
                    border-radius: 0;
                    background-color: transparent;
                    padding: 4px;
                    height: 24px;
                    position: relative;
                    z-index: 1;

                    + .timewrapper {
                      background: transparent;
                      width: 83px;
                      height: 24px;
                      position: absolute;
                      z-index: 0;
                    }

                    &:focus,
                    &:hover {
                      background: $white;
                      + .timewrapper {
                        background: $white;
                      }
                    }

                    &:focus {
                      text-transform: none;
                      outline: none;
                      border: none;
                      box-shadow: 0 0 5px $white;

                    }
                  }
                }

                input[type="radio"] + label{
                  width: auto;
                  float: left;
                }

                span.time-separator {
                  float: left;
                  position: relative;
                  z-index: 1;
                  line-height: 24px;
                }

                input[type="number"] {
                  width: 40px;
                  float: left;
                }

                button.Zebra_DatePicker_Icon {
                  background: url(../../../img/icon-calendar-red.svg) no-repeat center top;
                  top: 5px !important;
                  z-index: 1;
                }

                span.Zebra_DatePicker_Icon_Wrapper {
                  width: 200px !important;
                }
              }

              &.add-dates {
                td {
                  margin-right: 0;
                  background: transparent;

                  a.link {

                    &:last-child {
                      margin-right: 50px;
                    }
                  }
                }
              }
            }
          }

          .offers {
            .start,
            .end,
            span.Zebra_DatePicker_Icon_Wrapper {
              max-width: 120px;
            }

            textarea {
              resize: none;
              text-transform: none;

             &[id^="offer_description"] {
               width: 300px;
             }
            }

            p.filename {
              color: $vb-black;
              font-weight: 600;
            }

            input[id^="offer_link"] {

            }
          }

          legend {
            color: $vb-black;
            font-size: rem-calc(20px);
            font-weight: 600;
            margin-bottom: 20px;
          }

            table.offers {        
              tr {
                th,td {
                  padding: 9px 14px;
                  @include breakpoint(1120px) {
                    padding: 9px 24px;
                  }
                }
              }
            }
        }

        .pricing {
          float: left;
          margin-left: 20px;

          .table-wrap {
            float: left;
            margin-right: 0;

            @include breakpoint(1300px) {
              margin-right: 4px;
            }

            &:nth-child(n+4){
              margin-top: 20px;
            }

            &.no-delete {
              margin-top: 22px;
            }

            a.link {
              line-height: 20px;
              margin: 0px 0 2px 0;
              text-transform: uppercase;
              font-size: rem-calc(14px);
                span {
                  display: block;
                  margin-right: 6px;
                }
            }

            &.no-season {
              table tbody tr td:first-child {
                background-color: $lighter;
              }
              table thead tr:first-child th:first-child {
                background-color: $offset;
              }
            }
          }

          table {
            width: auto;
            border-spacing: 2px 0;

            thead {

              tr:first-child th {
                min-width: 62px;
                background-color: $offset;
                border-bottom: solid 2px $white;

                @include breakpoint(1300px) {
                  min-width: 180px;
                }

                .click2edit {
                  color: $white;

                  &:focus,
                  &:hover {
                    background-color: rgba(255,255,255,0.5);
                  }
                }
              }

              tr:last-child th {
                color: $dark;
                background-color: $mystic;
                border-bottom: solid 3px $white;
                font-size: rem-calc(10px);
              }
            }

            thead,
            tbody {
              tr td:first-child,
              tr:first-child th:first-child {
                display:none;
                @extend .title-small;
                font-weight: 400;
              
                text-align: right;
                background-color: transparent;
              }

              tr.season-type {
                td {
                  padding: 4px;
                  width: 62px;

                  @include breakpoint(1152px) {
                    padding: 4px 4px;
                    width: 98px;
                  }
                  @include breakpoint(1300px) {
                    padding: 4px 4px;
                    width: 100px;
                  }                  
                }

                td:first-child {
                  font-size: rem-calc(10px);
                  line-height: rem-calc(22px);

                  @include breakpoint(1300px) {
                    font-size: rem-calc(16px);
                  }
                }
              }
            }

            tr {
              background-color: transparent;

              td {
                width: 100px;
                background-color: $lighter;
                text-align: center;
                border: solid 1px $white;
                padding: 4px 15px;
              }

              th {
                padding: 4px;
                text-align: center;
              }
            }
          }

          &:first-of-type {
            margin-left: 0px;

            thead,
            tbody {
              tr td:first-child,
              tr:first-child th:first-child {
                color: $darker;
                font-weight: 600;
                display: table-cell;
              }
            }
          }

          .click2edit {
            margin: 0;
            color: $dark;
            text-align: center;
            font-size: rem-calc(18px);
            font-weight: 600;
            border-radius: 0;
            background-color: transparent;
            padding: 4px;
            height: 32px;
            position: relative;
            z-index: 1;

            &:focus,
            &:hover {
              background: $white;
              + .timewrapper {
                background: $white;
              }
            }

            &:focus {
              text-transform: none;
              outline: none;
              border: none;
              box-shadow: 0 0 5px $white;

            }
          }
        }
      }
    }
  }
}

#add_pricing_space1 {
 margin-top: 24px; 
}

ul.capacities.no-style {
  border-bottom: solid 1px $light;
  margin-bottom: 10px;

  li {
    display: inline-block;
    width: 225px;
    margin-right: 10px;

    label {
      color: $darker;
      font-size: rem-calc(18px);
      text-transform: none;
      font-weight: normal;
      float: left;
      width: auto;
      // margin-right: 15px;
      margin-top: 10px;
    }

    input {
      float: left;
      width: 60px;
      height: 40px;
      padding: 0px 6px 0 8px;
      margin: 0 5px 5px 0;
      border-radius: 4px;
      border: 1px solid $dark;
      font-size: 1rem;
    }
  }

  + .guide-price-wrap {
    margin: 0 auto;
    width: 100%;
    max-width: 440px;
  }
}

a.link {
  width: auto;
  display: block;
  float: right;
  margin: 0 0 10px 0;

  > span {
    float: left;
    margin-top: 4px;
    margin-right: 20px;
  }
}

.tabs.alphabet {
  border-bottom: solid 1px $lighter;
  padding-bottom: 10px;
  margin-bottom: 20px;

  .tabs-title {
    margin: 10px 3px;

    > a {
      font-size: rem-calc(24px);
      font-weight: 500;
      color: $offset;
      text-transform: uppercase;
      border-radius: 2px;
      padding: 8px 15px 8px 8px;
      border-top: none;
    }

    &.is-active {
      > a {
        color: $white;
        background: $rose;
        padding: 8px 15px;
      }
    }

    &.disabled {
      pointer-events: none;
      > a {
        pointer-events: none;
        color: $lighter;
      }
    }
  }
}

input[name="alcohol"] + label {
  font-size: rem-calc(14px);
  font-weight: 500;
  text-transform: none;
  color: $darker;
  margin: 8px;
}

#dropzone_space1 {
  min-height: 180px;

  > .multibox.file {
    float: left;
    overflow: visible;
    padding: 12px;

    .link {
      cursor: pointer;
    }
  }

  .dz-filename {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
  }

  .dz-preview.dz-file-preview {
    img {
      width: 48px;
    }
  }
}

div[id^="images"] {
  margin-top: 12px;
}

.other-attr-wrap {
  margin: 0 auto;
  width: 100%;
  max-width: 290px;

  input[type="radio"].tick + label,
  input[type="checkbox"].tick + label {
    min-width: 1px;
    margin: 19px 15px 0 0;
  }
}

.stats-duration {
  float: right;
  margin-top: -70px;
  width: 203px;
  height: 36px;
  padding: 0px 6px;
}


/*Disable stats for silver users*/
.dashboard-silver {
  .upgrade-stats {
    display: block !important;
    position: absolute;
    top: 45%;
    bottom: 0;
    left: 0;
    right: 0;
    height: 56px;
    z-index: 1;
  }
 
  .lone-header {
    opacity: 0.25;
  }
 
  .amchart {
    pointer-events: none;
    opacity: 0.2;
  }
 }
 