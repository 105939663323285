.Zebra_DatePicker {
    background: #fff;
    border: 1px solid #aaa;
    border-radius: 4px;
    -webkit-box-shadow: 2px 3px 17px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 3px 17px -6px rgba(0,0,0,0.75);
    box-shadow: 2px 3px 17px -6px rgba(0,0,0,0.75);
    color: #222;
    font: 13px Tahoma, Arial, Helvetica, sans-serif;
    padding: 5px 5px;
    position: absolute;
    display: table;
    width: 255px;
    z-index: 1200;
}

.Zebra_DatePicker *,
.Zebra_DatePicker *:after,
.Zebra_DatePicker *:before {
    box-sizing: content-box !important;
}

.Zebra_DatePicker * {
    padding: 0;
}

.Zebra_DatePicker table {
    border-collapse: collapse;
    border-radius: 4px;
    border-spacing: 0;
    width: 100%;
}

.Zebra_DatePicker th, .Zebra_DatePicker td {
    border-radius: 4px;
    padding: 5px;
    cursor: pointer;
    text-align: center;
    min-width: 25px;
    width: 25px;
}

.Zebra_DatePicker .dp_body .dp_not_in_month {
    color: #666;
}

.Zebra_DatePicker .dp_body .dp_current {
    color: #3a87ad;
}

.Zebra_DatePicker .dp_body .dp_selected {
    background: $offset;
    color: #fff;
}

.Zebra_DatePicker .dp_body .dp_disabled {
    color: #bbbbbb;
    cursor: text;
}

.Zebra_DatePicker .dp_body .dp_disabled.dp_current {
    color: #b4d5e6;
}

.Zebra_DatePicker .dp_body .dp_hover {
    background: #dedede;
}

.Zebra_DatePicker .dp_body .dp_hover.dp_time_control {
    background-color: #dedede;
}

.Zebra_DatePicker .dp_monthpicker td,
.Zebra_DatePicker .dp_yearpicker td,
.Zebra_DatePicker .dp_timepicker td {
    width: 33.3333%;
}

.Zebra_DatePicker .dp_timepicker .dp_disabled {
    border: none;
    color: #222;
    font-size: 39px;
    font-weight: bold;
}

.Zebra_DatePicker .dp_time_separator div {
    position: relative;
}

.Zebra_DatePicker .dp_time_separator div:after {
    content: ":";
    color: #222;
    font-size: 37px;
    left: 100%;
    position: absolute;
    z-index: 1;
}

.Zebra_DatePicker .dp_header {
    margin-bottom: 5px;
}

@supports (-ms-ime-align: auto) {
    .Zebra_DatePicker .dp_header {
        font-family: 'Segoe UI Symbol', Tahoma, Arial, Helvetica, sans-serif;
    }
}

.Zebra_DatePicker .dp_footer {
    margin-top: 5px;
}

.Zebra_DatePicker .dp_footer .dp_icon {
    width: 50%;
}

.Zebra_DatePicker .dp_actions td {
    border-radius: 4px;
    background-image: none;
    -webkit-appearance: none;
}

.Zebra_DatePicker .dp_actions .dp_caption {
    font-weight: bold;
    width: 100%;
}

.Zebra_DatePicker .dp_actions .dp_previous,
.Zebra_DatePicker .dp_actions .dp_next {
    *padding: 0 10px;
    font-size: 26px;
    line-height: 12px;
}

.Zebra_DatePicker .dp_actions .dp_hover {
    background-color: #dedede;
}

.Zebra_DatePicker .dp_daypicker th {
    cursor: text;
    font-weight: bold;
    color: $vb-black;
}

.Zebra_DatePicker.dp_hidden {
    display: none;
}

.Zebra_DatePicker .dp_icon {
    height: 16px;
    background-image: url("../../../img/icon-calendar.svg");
    background-repeat: no-repeat;
    text-indent: -9999px;
    *text-indent: 0;
}

.Zebra_DatePicker .dp_icon.dp_confirm {
    background-position: center -123px;
}

.Zebra_DatePicker .dp_icon.dp_view_toggler {
    background-position: center -91px;
}

.Zebra_DatePicker .dp_icon.dp_view_toggler.dp_calendar {
    background-position: center -59px;
}

button.Zebra_DatePicker_Icon {
    background: url("../../../img/icon-calendar.svg") no-repeat center top;
    border: none;
    cursor: pointer;
    display: block;
    height: 16px;
    line-height: 0;
    padding: 0;
    position: absolute;
    text-indent: -9000px;
    width: 16px;
    top: 12px !important;
}

button.Zebra_DatePicker_Icon.Zebra_DatePicker_Icon_Disabled {
    background-position: center -32px;
    cursor: default;
}

.Zebra_DatePicker {
    table tbody tr td {
      border-right: none;
    }
}


.dp_today, .dp_clear {
  background: $rose;
  color: white;
  text-transform: uppercase;
  border-radius: 2px;
  border-right: 4px solid $white;
}

.dp_clear {
    background: $dark;
    margin-right: 0;
    border-left: 4px solid $white;
}