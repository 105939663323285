.accordion {
  padding-left: 0;
  margin-top: 0;

  .accordion-title {
    border: none;
    color: $vb-black;

    &:last-child:not(.is-active) > .accordion-title {
      border: none;
    }
  }

  .accordion-content {
    border: none;
  }

  :last-child > .accordion-content:last-of-type {
    border-bottom: 0px solid #e6e6e6;
  }

}