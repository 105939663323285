section.your-bookings {
  text-align: center;
  h2 {
    text-align: left;
  }
  table {
    margin-bottom: 2px;
    text-align: left;
  }
  td {
    font-weight: 600;
    border-bottom: 1px solid $lighter;
  }
}

.tabs-table, .bookings-table {
  thead tr th {
    width: 16.66666666666667%;
  }
  thead tr th.small {
    @include breakpoint(large) {
      width: 3%;
    }
  }
}

.tabs-table, .supplier-table {
  /*thead tr th {
    width: 30%;
  }*/
  tr td.actions {
    min-width: auto !important;
    width: auto !important;
    float: none !important;
  }
  tr td.actions .button.contact {
    float: none !important;
    margin: 0 auto !important;
    left: -9px;   
    text-align: center;
  }
}

.your-leads,
.bookings-table {
  @include breakpoint(medium) {
    tr th:last-child {
      //width: 24%;
    }
  }

  tr {
    td.actions {
      text-align: right;

      .button {
        display: inline-block;
        float: right;
        margin: 0 auto 8px;

        &.green {
          float: none;
          clear: both;
        }

        &.contact {
          float: left;
        }

        &.delete {
          margin-right: 0;

          &.disabled {
            border-color: $light;
            background-color: transparent;
            cursor: not-allowed;

            &::before,
            &::after {
              background: $light;
            }
          }
        }
      }

      @include breakpoint(medium down) {
        .button {
          height: auto;
        }
      }

      @include breakpoint(large) {
        width: 200px;

        .button {
          margin: 0 5px;

          &.green {
            float: left;
            margin-left: 0;
          }

          &.contact {
            margin-right: 0;
            float: right;
          }

          &.delete {
            margin-right: 0;

            + .contact {
              margin-right: 5px;
            }
          }
        }
      }

    }
  }
}

.your-leads {
  a.button {

    &.green {
      width: 80px;
    }
  }
}

.bookings-table {
  tr {
    td.actions {
      @include breakpoint(large) {
        min-width: 270px;

        a.button {

          &.green {
            width: 152px;
            max-width: 152px;

            + .contact {
              float: left;
            }
          }
        }
      }
    }
    td.actions.small {
      @include breakpoint(large) {
        min-width: 186px;
      }

      .button {
        display: block;
        float: none;
      }
    }    
  }
}