/*Dashboard Tabs Navigation*/
.tabs-db-nav {
  padding: 20px 0 0;
  background: transparent;
  border: none;

  .grid-x {
    margin-top: 10px;
    margin-bottom: 20px;

    @include breakpoint(large) {
      margin-top: 0;
      margin-bottom: 0;
    }
    
  }

  @include breakpoint(large) {
    background: $white;
    text-align: center;
    margin-bottom: 100px;
    padding: 0;
  }

  a {
    display: block;
    background: $brand;
    color: $white;
    text-transform: uppercase;
    text-align: center;
    padding: 10px 0;
    font-size: .7rem;
    letter-spacing: 1px;
    position: relative;

    @include breakpoint(large) {
      color: $brand;
      background: $white;
      text-transform: uppercase;
      font-weight: 600;
      font-size: rem-calc(16px);
      display: block;
      padding: 18px 0;

      &:hover {
        color: $offset;
      }
    }

    @include breakpoint(1280) {
      font-size: rem-calc(22px);
    }

    img {
      width: 20px;
      display: block;
      margin: 0 auto;

      @include breakpoint(400px) {
        display: inline-block;
      }

      @include breakpoint(large) {
        display: none;
      }
    }

    &.middle {
      border-right: 1px solid $white;
      border-left: 1px solid $white;
    }

    &.active {
      background: $offset;

      @include breakpoint(large) {
        background: $white;
        color: $offset;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: -10px;
        margin: auto;
        left: 0;
        right: 0;
        height: 0;
        width: 0;
        border-top: 10px solid $offset;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;

        @include breakpoint(large) {
          bottom: -40px;
          height: 20px;
          width: 20px;          
          border-top: 40px solid $white;
          border-left: 40px solid transparent;
          border-right: 40px solid transparent;
        }
      }
    }
  }
}