.title {
  font-weight: normal;
  font-size: rem-calc(20px);
  line-height: 27px;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  margin: 30px 0;

  @include breakpoint(medium) {
    font-size: rem-calc(30px);
    line-height: 37px;
  }
}

.title-banner {
  @extend .title;
  font-family: "nexa_lightregular";
  font-weight: 500;
  text-transform: none;
  font-size: rem-calc(30px);
  line-height: 30px;
  color: $white;  

  @include breakpoint(medium) {
    font-size: rem-calc(45px);
    line-height: 45px;
  }
}

.title-small {
  @extend .title;
  font-size: rem-calc(16px);
  line-height: 18px;
  letter-spacing: inherit;
  margin: 20px 0;

  @include breakpoint(medium) {
    font-size: rem-calc(20px);
    line-height: 22px;
  }
}

.title-large {
  @extend .title;
  font-size: rem-calc(25px);
  line-height: rem-calc(40px);
  letter-spacing: inherit;
  margin: 0px 0px 50px;

  @include breakpoint(medium) {
    font-size: rem-calc(40px);
    line-height: 22px;
  }
}

.title-bold {
  font-size: rem-calc(20px);
  text-transform: uppercase;
  font-weight: 700;
}

.title-highlight {
  color: $rose;
}

.title-logo {
  background: url(../../../img/logo-symbol-black.svg) center top no-repeat;
  padding-top: 66px;
  background-size: 56px 56px;
  margin-top: 38px;
}

.title-logo-red {
  @extend .title-logo;
  background-image: url(../../../img/logo-symbol-red.svg);
}

.title-no-vert-spacing {
  margin-top: 0;
  margin-bottom: 0;    
}

.underline {
  position: relative;
  margin-bottom: 30px;

  &::after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-top: 1px solid $brand;
    width: 66px;
    height: 1px;
  }
}

.underline-black {
  @extend .underline;
  &::after {
    border-color: $vb-black;
  }
}

.notification-header {
  position: relative;
  min-height: 65px;
  .notification {
    vertical-align: top;
    position: absolute;
    top: 0;
  }
}

.table-heading {
  h2.title-small {
    position: relative;

    .notification {
        top: -24px;
    }
  }
}

.title-lower {
  @extend .title;
  font-size: rem-calc(22px);
  letter-spacing: 0;
  margin: 0;
  font-weight: 600;
  text-transform: none;
  
  & + p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: rem-calc(16px);

    span.small {
      font-size: 13px;
    }
  }

  span.small {
    font-size: 13px;
    font-weight: 300;
  }

  &.large {
    font-size: rem-calc(36px);
  }
}

.lone-header {
  padding: 0 15px;
  h2 {
    @include breakpoint(medium) {
      padding-bottom: 20px;
      border-bottom: 2px solid $lighter;
    }
  }
}
