section.commision {
  .commision-text {
    text-align: center;
    padding: 30px 0 40px;

    p {
      color: $dark;
      margin: 0;
      font-size: 1.1rem;

      &.stat {
        color: $brand;
        font-size: rem-calc(68px);
        font-weight: 600;
        margin: 0;
      }
    }
  }
}
