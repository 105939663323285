body {
  color: $vb-black;

  &.logged-in:not(.packages) {
    background: $mystic;
  }
}

a {
  color: $brand;
}

*:focus {
  outline: 0;
}


.banner-overlay {
  background-color: $white;
  position: relative;
  z-index: 9;
  max-width: 100%;
  padding-top: 50px;

  @include breakpoint(medium) {
    max-width: 94%;
    margin-top: -50px;
    text-align: left;
  }

  @include breakpoint(large) {
    margin-top: -175px;
  }
  @include breakpoint(1300) {
    max-width: 1264px;
  }

  .title {
    margin-top: 0px;
  }

  &:not(.full) > .grid-padding-x {
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;


    @include breakpoint(1280) {
      max-width: 94%;
    }
  }

  //iPhone 6, 7, 8 landscape
  @media only screen
  and (min-device-width : 375px)
  and (max-device-width : 667px)
  and (orientation : landscape) {
    margin-top: 0;
  }
  //iPhone X landscape
  @media only screen
  and (min-device-width : 375px)
  and (max-device-width : 812px)
  and (-webkit-device-pixel-ratio : 3)
  and (orientation : landscape) {
    margin-top: 0;
  }


  .title-small {
    text-align: left;
    margin: 0;

    &.underline {
      margin-bottom: 30px;
    }
  }
}

.img-link {
  display: block;

  div {
    position: relative;
    width: 100%;
    height: 100%;
    @include transitions(all 1s);

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 1px;
      width: 29px;
      height: 29px;
      background-color: rgba(0,0,0,0.4);
      background-image: url(../../../img/icon-dropdown-white-right.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 8px;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}

//Adds a hover opacity
.hover-link {
  @include transitions(all 1s);

  &:hover {
    opacity: .8;
  }

  &:after {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  &.red {
    z-index: 1;
    position: relative;
    text-align: left;
    text-transform: uppercase;
    padding: 5px 10px;
    display: flex !important;
    justify-content: flex-end;
    flex-direction: column;
    &:after {
      background: transparentize($brand, 0.2);
      opacity: 0;
      @include transitions(all 1s);
    }
    &:hover {
      opacity: 1;
      color: $white;
      &:after {opacity: 1}
    }
  }
  &.yellow {
    @extend .red;
    &:after {
      background: transparentize($prime, 0.2);
    }
  }
  &.blue {
    @extend .red;
    &:after {
      background: transparentize($offset, 0.2);
    }
  }
}


.admin-wrapper {
  section {
    background: $white;
    border-radius: 10px;
    padding: 10px 0;
    margin-bottom: 1.875rem;
    position: relative;

    .grid-x {
      margin-top: 0;
      margin-bottom: 0;
    }

    .wrapper {
      padding: 0 20px;
    }

    & > .title-small {
      margin-left: 1.875rem;
    }

    .wrap{
      padding: 0 14px;
      margin-top: 14px;
    }
  }
}

.notification {
  background: $brand;
  color: $white;
  border-radius: 100%;
  display: inline-block;
  margin: 0;
  width: 25px;
  height: 25px;
  font-size: rem-calc(12px);
  line-height: 25px;
  text-align: center;

  &.offset {
    background: $white;
    color: $offset;
  }

  @include breakpoint(small down) {
    background: $offset;
    color: $white;
  }
}

.notification-small {
  width: 18px;
  height: 18px;
  line-height: 19px;
  font-size: 12px;
}


.tooltip-title {
  display: inline-block;
}

.information-tooltip {
  position: relative;
  top: -19px;
  right: 0px;
  max-width: 24px;
  z-index: 2;
  display: inline-block;

  &:hover {
    max-width: 400px;
    .information {
      display: block;
      text-transform: initial;
    }
  }

  img {
    display: block;
    margin-left: auto;
    margin-right: 0;
    margin-bottom: -24px;
    cursor: pointer;
  }

  .information {
    display: none;
    position: absolute;
    left: 0;
    background: $white;
    padding: 20px;
    border: 1px solid $darker;
    border-radius: 7.5px;
    box-shadow: 1px 1px 3px 0px;
    text-align: left;
    width: 300px;

    h3 {
      margin: 0;
      font-size: rem-calc(16px);
    }

    .grey {
      background: $lighter;
      margin: 5px -20px;
      padding: 0 20px;
    }

    h5 {
      margin-bottom: 0;
    }

    p {
      margin-top: 0;
      font-weight: normal;
    }
  }

  &.left {
    .information {
      right: auto;
      left: 0;
    }
  }
}

.information-mobile {
  background: $lighter;
  text-align: right;
  padding: 5px;
}

.load-more {
  color: $brand;
  display: inline-block;
  text-transform: uppercase;
  font-size: rem-calc(14px);

  img {
    width: 20px;
    display: block;
    margin: 4px auto 0;
  }
}

.centre-text {
  text-align: center;
}


.grid-x {
  margin-top: 40px;
  margin-bottom: 40px;
}

.no-v-spacing {
  margin-top: 0;
  margin-bottom: 0;
}

.no-v-spacing-bottom {
  margin-bottom: 0;
}

.no-v-spacing-top {
  margin-top: 0;
}

.pad-wrapper {
  width: 80%;
  margin: 0 auto;

  .button {
    min-width: 0px;
  }
}


.bold {
  font-weight: 600;
}

#back2top {
  display: block;
  transition: all 300ms;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: fixed;
  z-index: 10;
  cursor: pointer;
  bottom: 50px;
  right: 50px;
  background: $mystic;

  img {
    transform: rotate(180deg);
    width: 50%;
    display: block;
    margin: 21px auto 0;
  }
}



body:not(.scroll) {
  #back2top {
    display: none;
  }
}


ul.pagination {
  margin: 0 0 30px;
  padding: 0;
  text-align: center;

  li {
    display: inline-block;
    list-style-type: none;
    margin: 0 4px 0 0;
    padding: 0;
    color: $light;

    &:last-child {
      margin-right: 0;
    }

    a {
      display: block;
    }
  }
}
