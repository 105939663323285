@charset 'utf-8';

// Grid Overrides
$global-width: 1280px;
$grid-columns: 24;
$grid-margin-gutters: (
  small: 15px,
  medium: 15px
);
$breakpoints: (
  small: 0,
  medium: 767px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
);

@import 'globals/colours';

@import 'globals/fingo-mixins';
@import 'foundation';
@import 'settings';
@import 'motion-ui';

@include foundation-global-styles;
//@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-xy-grid-classes;
// @include foundation-typography;
// @include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-button-group;
// @include foundation-callout;
// @include foundation-card;
// @include foundation-close-button;
// @include foundation-menu;
// @include foundation-menu-icon;
// @include foundation-drilldown-menu;
@include foundation-dropdown;
// @include foundation-dropdown-menu;
// @include foundation-responsive-embed;
// @include foundation-label;
// @include foundation-media-object;
// @include foundation-off-canvas;
// @include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-progress-element;
// @include foundation-meter-element;
// @include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
// @include foundation-switch;
// @include foundation-table;
@include foundation-tabs;
// @include foundation-thumbnail;
// @include foundation-title-bar;
//@include foundation-tooltip;
// @include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

@import url("https://use.typekit.net/ges8lis.css");

@import 'globals/fonts';
@import 'globals/globals';
@import 'globals/forms';

@import 'components/dashboard/dashboard-nav';
@import 'components/dashboard/dashboard-banner';
@import 'components/dashboard/toggle';
@import 'components/dashboard/info-icon';
@import 'components/dashboard/commision';
@import 'components/dashboard/messaging';
@import 'components/dashboard/password';
@import 'components/dashboard/simplebar';
@import 'components/dashboard/venue-details';
@import 'components/dashboard/venue-details-edit';
@import 'components/dashboard/your-bookings';
@import 'components/dashboard/amc-export';
@import 'components/dashboard/amc-global';
@import 'components/dashboard/member-dashboard';
@import 'components/dashboard/orakuploader';
@import 'components/dashboard/orakuploader-overrides';
@import 'components/dashboard/space-tabs';
@import 'components/dashboard/fullcalendar-base';
@import 'components/dashboard/fullcalendar-override';
@import 'components/dashboard/venue-dashboard-edit';
@import 'components/loading';
@import 'components/cookie';

@import 'components/text';
@import 'components/tables';
@import 'components/headings';
@import 'components/buttons';
@import 'components/slick';
@import 'components/slick-theme';
@import 'components/hamburgers';
@import 'components/banner-title';
@import 'components/social';
@import 'components/zebra_datepicker';
@import 'components/accordion';
@import 'components/map';
@import 'components/reviews';
@import 'components/isotope';
@import 'components/awesomplete';
@import 'components/reveal';
@import 'components/icon-grid';
@import 'components/tabs-override';
@import 'components/countdown';

@import 'layouts/header';
@import 'layouts/footer';

@import 'pages/home';
@import 'pages/search';
@import 'pages/blog';
@import 'pages/generic';
@import 'pages/suppliers';
@import 'pages/booked';
@import 'pages/how-it-works';
@import 'pages/event-planner';
@import 'pages/packages';
@import 'pages/venue-detail';
@import 'pages/faqs';
@import 'pages/venue-dashboard-suppliers';
@import 'pages/suppliers-dashboard-edit';
@import 'pages/suppliers-detail';
