.info-panel {
  background: $offset;
  height: 32px;

  .info-icon {
    display: block;
    float: right;
    margin-right: 14px;
    margin-top: 4px; 
  }
}

.info-icon {
  display: block;
  margin: 0 auto;
  height: 24px;
  width: 24px;
}


