.search-results {
  .grid-container {
    position: relative;
  }

  #search-form {
    input[type="text"],
    select {
      padding-left: 10px;
    }

    .Zebra_DatePicker_Icon_Wrapper {
      input {
        font-size: rem-calc(16px);
        padding-right: 45px !important;
      }
    }
  }

  .accordion {
    .accordion-title,
    .accordion-title + select {
      width: 50%;
      float: left;
      font-size: rem-calc(16);
      height: 40px;
    }

    .accordion-title {
      line-height: 40px;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 17px;
      background-color: $mystic;
      width: 100%;

      @include breakpoint(large) {
        display:none;
      }

      &::before {
        top: 11px;
        left: 25%;
        height: 16px;
        width: 16px;
        margin-top: 0;
        content: '' !important; // important tag justified because there should never be content in here - we're using background image instead
        background-image: url("../../../img/filter.svg");
        background-position: center;
        background-size: 100%;

        @include breakpoint(medium) {
          left: 45%;
        }
      }
    }

    .accordion {
      .accordion-item {
        border-bottom: solid 1px $dark;
        &:last-child {
          border-bottom: none;

          .accordion-title {
            border-bottom: none;
          }
        }
        .accordion-title {
          @extend .title-small;
          font-size: rem-calc(14);
          letter-spacing: 1.0px;
          display:block;
          color: $rose;
          padding-left: 0;
          width: 100%;
          height: 55px;
          line-height: 60px;
          float: none;
          margin: 0;
          border-bottom: none;

          &::before {
            top: 20px;
            left: 94%;
            background-image: url(../../../img/icon-dropdown.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100%;
            transition: all 300ms;
          }

        }
        .accordion-content {
          display: none;
          padding-top: 18px;
          padding-bottom: 4px;
          background: $geyser;
        }

        &.is-active {
          .accordion-title::before {
            transform: rotate(180deg);
          }
        }
      }
      & + input {
        @include breakpoint(large) {
          display: none;
        }
      }
    }

    .accordion-content {
      background-color: $mystic;
      clear: both;
      display: block;
      padding: 10px 19px;
      border-bottom: none;

      h2 {
        color: $vb-black;
        font-size: rem-calc(16);
        padding: 15px 0;
        display: none;
        margin: 0;

        @include breakpoint(large) {
          display: block;
        }
      }

      hr {
        display: none;
        margin: 0;

        @include breakpoint(large) {
          display: block;
        }
      }
    }
  }

  label[for="sort"] {
    position: absolute;
    top: 11px;
    margin-top: 0px;
    right: 42%;
    width: 75px;
    z-index: 2;

    @include breakpoint(medium) {
      right: 41%;
    }

    @include breakpoint(large) {
      position: relative;
      display:inline;
      right: 0;
      top:0;
      margin: 0;
      left: 0;
      width: 65px;
    }
  }

  select#sort {
    background-position: 100% 7px;
    font-size: 13px;
    padding-left: 24%;
    padding-top: 9px;
    position: absolute;
    top: 0;
    margin-top: 0px;
    width: 65%;
    right: 0;
    height: 40px;
    border: none;
    background-size: 20px;
    padding: 0.5rem 10px 0.5rem 10px;
    border-radius: 0px;


    @include breakpoint(medium) {
      height: 42px;
      width: 50%;
      padding-left: 9%;
    }

    @include breakpoint(large) {
      position: relative;
      border-radius: 4px;
      left: 5px;
      width: calc(50% - 75px);
      margin-top: -80px;
      background-position: 100% 7px;
      font-size: 1.125rem;
      background-color: white;
      color: #383838;
      text-transform: none;
      font-weight: 500;
      font-size: 18px;
      border: 1px solid #979797;
      margin: 3px 0;
      height: 40px;
      line-height: 16px;
      padding: 0.5rem 10px 0.5rem 10px;

      option.mobile {
        display:none;
      }
    }
  }

}

.search-form {

  [type='text'] {
    display: inline-block;
  }

  select, textarea,
  input:not([type="submit"]) {
    background-color: white;
    color: $vb-black;
    text-transform: none;
    font-weight: 500;
    font-size: 18px;
    border: 1px solid #979797;
    margin: 3px 0;
    height: 40px;
    line-height: 16px;

    &::placeholder {
      color: $vb-black;
    }
  }

  select {
    background-position: 102% 2px;
    background-size: 20px 20px;
  }

  .radiocheck-list {
    padding: 16px 0 30px;
  }

  button.Zebra_DatePicker_Icon {
    background: url("../../../img/icon-calendar-black.svg") no-repeat center top;
  }

  .Zebra_DatePicker_Icon_Wrapper {
    @include breakpoint(medium) {
      &:first-of-type {
        width: calc(50% - 3px);
      }
      &:last-of-type {
        width: calc(50% - 2px);
      }
    }
  }

  button.Zebra_DatePicker_Icon {
    margin-top: 2px;
  }

  .radiocheck-list li {
    width: 50%;
    float: left;
    line-height: 10px;
  }

  input[type="submit"] {
    @extend .button;
    width: 100%;
    margin:10px 0;
  }

  input[type="checkbox"] {
    & + label {
      background-color: $offset;
      display: block;
      width: auto;
      float: left;
      margin: 0 10px 14px 0;
      border-radius: 4px;
      text-transform: none;
      font-size: rem-calc(15);
      font-weight: 600;
      line-height: 24px;
      color: white;
      padding: 5px 10px;
      cursor: pointer;

      &.dashboard-platinum,
      &.dashboard-gold,
      &.dashboard-silver {
        position: relative;

        &::before {
          position: absolute;
          top: -22px;
          color: $dark;
          font-size: 10px;
          font-weight: 300;
        }
      }

      &.dashboard-platinum {
        background: $sub-plat;
        margin-top: 14px;

        &::before {
          content: 'Featured';
          color: $sub-plat;
        }
      }

      &.dashboard-gold {
        background: $sub-gold;
        margin-top: 14px;

        &::before {
          content: 'Recommended';
          color: $sub-gold;
        }
      }

      &.dashboard-silver {
        background: $sub-silv;
      }

      &.dashboard-clear {
        background: $white;
        color: $offset;
        border: 1px solid $offset;

        span:not(.has-tip) {
          border-color: $offset;
        }
      }

      //span:not(.top),
      span:not(.has-tip) {
        background-color: transparent;
        border: solid 1px white;
        transform: rotate(45deg);
        width: 12px;
        height: 12px;
        margin: 0;
        border-radius: 2px;
        margin-left: 0;
        margin-right: 8px;
        position: relative;
        right: -6px;

        &::after {
          transition: all 300ms;
          opacity: 0;
          content: '';
          position: absolute;
          top: 0px;
          left: 0px;
          border-radius: 2px;
          width: 10px;
          height: 10px;
          background-color: $rose;
          border-radius: 2px;
        }
      }

      //span:not(.top),
      span.left:not(.has-tip) {
        position: relative;
        right: inherit;
      }

      > button {
        height: 20px;
        width: 20px;
        margin-right: 4px;
      }

      + hr {
        clear: both;
        margin: 15px 0;
        border: none;
        border-top: solid 1px $lighter;
        background: transparent;
      }
    }

    &:checked {
     & + label.dashboard-clear {
        span {
          border: solid 1px pink;
        }
      }

      & + label {
        span {
          border: solid 1px $rose;
          &::after {
            opacity: 1;
          }
        }
      }
    }
  }

  .search-ddr {
    overflow: hidden;
  }
}

.grid-padding-x > .cell.search-result {
  padding-left: 0;
  padding-right: 0;
}

.search-result {
  margin-bottom: 30px;
  padding-bottom: 8px;
  background: $mystic;
  border: solid 0.46875rem #FFF;

  &:hover {
    z-index:2;
  }

  &:last-child {
    background-color: inherit;
  }

  a {
    color: $dark;
    display: block;
  }

  .image {
    position: relative;
    width: 100%;
    height: 225px;
    margin-bottom: 15px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include breakpoint(large) {
      height: 250px;
    }
  }


  .overlay {
    height: 30px;
    background-color: rgba(0,0,0,0.33);
    display: none;

    .status {
      height: 30px;
      display: inline-block;
      padding: 0 10px;
      font-size: rem-calc(14);
      font-weight: bold;
      letter-spacing: 1px;
      line-height: 31px;
      text-align: center;
      text-transform: uppercase;

      &.held {
        color: white;
        background-color: $rose;
      }

      &.previous {
        color: $rose;
        background-color: white;
        border: solid 1px $rose;
      }

      &.held-by-you {
        color: white;
        background-color: $offset;
        border: solid 1px $offset;        
      }
    }

    .review {
      float:right;
      width: 120px;
      height: 100%;
      margin-right: 4px;
    }
  }

  ul.capacity-list {
    padding: 0;
    top: 11px;
    position: relative;

    li {
      list-style-type: none;
      float: right;

      &:first-child {
        float: left;        
      }
      &:last-child {
        margin-right: 10px;
      }      
    }

  }

  .information-tooltip {
    max-width: none;
    position: relative;
    display: block;

    &:hover {
      max-width: none;
    }

    .information { 
      padding: 6px;
      left: 10px;
      width: 80px;
      z-index: 9999999999999;
    }
  }

  .text {
    padding: 0 35px;

    @include breakpoint(large) {
      padding: 0 6px;
    }

    h2,
    .location {
      color: $vb-black;
    }

    h2 {
      font-size: rem-calc(14);
      letter-spacing: 0.3px;
      min-height: 50px;
    }

    p {
      margin: 0;

      &.unavailable {
        font-weight: 600;
      }

      &.price,
      &.capacity {
        color: $darker;
      }

      &.price {
        font-weight: 600;
        &:before {
          content: '';
          width: 25px;
          height: 25px;
          display: inline-block;
          background: url(../../../img/icon-small-coins.svg);
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          margin-right: 5px;
          vertical-align: sub;
        }
      }

      &.capacity {
        &:after {
          content: '';
          width: 25px;
          height: 25px;
          display: inline-block;
          background: url(../../../img/small-profile.svg);
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          margin-left: 5px;
          vertical-align: sub;
        }
      }

      &.cabaret {
        &:after {
          background: url(../../../img/icon-seating-cabaret.svg);
        }
      }
      &.standing {
        &:after {
          background: url(../../../img/icon-seating-standing.svg);
        }
      }
      &.dining {
        &:after {
          background: url(../../../img/icon-seating-dining.svg);
        }
      }
      &.theater {
        &:after {
          background: url(../../../img/icon-seating-theatre.svg);
        }
      }
      &.dinnerdance {
        &:after {
          background: url(../../../img/icon-seating-dinnerdance.svg);
        }
      }
      &.boardroom {
        &:after {
          background: url(../../../img/icon-seating-boardroom.svg);
        }
      }
      &.ushape {
        &:after {
          background: url(../../../img/icon-seating-ushape.svg);
        }
      }
      &.classroom {
        &:after {
          background: url(../../../img/icon-seating-classroom.svg);
          width: 24px;
        }
      }            

    }
    hr {
      color: white;
    }
  }

}

.availableRoomWrapper {
  width: 100%;

  .availableRoom.search-result {
    width: 100%;
    border: solid 15px;
    background: #F1F5F6;

    .status {
      font-size: 22px;
      line-height: 27px;
      color: $vb-black;

      span {
        font-weight: bold;
      }
    }

    &.green {
      border-color: #BBD1D2;
    }

    &.red {
      border-color: #F3D5D4;

      .status span {
        color: #CF5854;
      }
    }

    ul.capacity-list {
      display: inline-block;
      margin-bottom: 0;
      width: 100%;
    }

    .text {
      padding: 0 20px;
    }

    @include breakpoint(medium) {
      padding-bottom: 0px;
      height: 255px;

      .image,
      .text {
        margin-bottom: 0;
        width: 50%;
        display: inline-block;
      }

      .image {
        float: left;
      }

      .text {
        h2 {
          min-height: 0px;
          margin: 0;
        }

        .status {
          font-size: 18px;
          line-height: 23px;
          margin-top: 10px;
        }
      }
    }

    @include breakpoint(medium) {
      height: 280px;

      .text {
        h2 {
          min-height: 40px;
        }

        .status {
          font-size: 22px;
          line-height: 27px;
          margin-top: 10px;
        }
      }
    }

    @include breakpoint(xlarge) {
      .text {
        h2 {
          min-height: 50px;
          margin: 10px 0;
        }
      }
    }
  }

  @include breakpoint(large) {
    margin: 25px 0;
    padding-right: 0.46875rem;
    padding-left: 0.46875rem;
  }
}

ul#search-tabs {
  display: none;
  width: 50%;
  float: right;

  .tabs-title {
    float: right;

    // map
    &:first-child > a {
      &::before {
        background-image: url(../../../img/icon-map-black.svg);
      }

      &:hover,
      &:focus,
      &[aria-selected='true'] {
        &::before {
          background-image: url(../../../img/icon-map-red.svg);
        }
      }
    }

    // list
    &:last-child > a {
      &::before {
        background-image: url(../../../img/icon-list-black.svg);
      }

      &:hover,
      &:focus,
      &[aria-selected='true'] {
        &::before {
          background-image: url(../../../img/icon-list-red.svg);
        }
      }
    }

    > a {
      position: relative;
      transition: all 300ms;
      font-size: rem-calc(16);
      font-weight: 500;
      color: $dark;
      background: transparent;
      padding: 1.25rem 35px;

      &:hover {
        color: $rose;
        background: transparent;
      }

      &:focus,
      &[aria-selected='true'] {
        color: $rose;
        background: transparent;
      }

      &::before {
        position: absolute;
        width: 28px;
        height: 24px;
        margin-top: 0;
        content: '';
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        left: 0;
        top: 16px;
      }
    }
  }

  @include breakpoint(large) {
    display: block;
  }
}


/*autocomplete*/
.live-search-list {
-webkit-box-sizing: border-box;
-moz-box-sizing: border-box;
box-sizing: border-box;
padding: 1em;
background-color: #2c3e50;
-webkit-border-radius: 5px;
-moz-border-radius: 5px;
border-radius: 5px;
font-family: 'Lato', sans-serif;
color: #fff;
}

.live-search-box {
width: 100%;
display: block;
padding: 1em;
-webkit-box-sizing: border-box;
-moz-box-sizing: border-box;
box-sizing: border-box;
border: 1px solid #3498db;
-webkit-border-radius: 5px;
-moz-border-radius: 5px;
border-radius: 5px;
}

.live-search-list li {
color: fff;
list-style: none;
padding: 0;
margin: 5px 0;
}


.no-results {
  position: relative;

  .image {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  .text {
    position: absolute;
    top: 36px;
    left: 0;
    right: 0;
    text-align: center;
    max-width: 78%;
    margin: 0 auto;
    color: $white;

    .title-small {
      color: $white;
      text-transform: uppercase;
      position: relative;
      font-size: rem-calc(20px);

      &:after {
        content: '';
        position: relative;
        top: 0;
        height: 1px;
        width: 150px;
        display: block;
        background-color: $white;
        margin: 20px auto 0;
      }
    }

    p {
      font-size: rem-calc(16px);
    }
  }
}