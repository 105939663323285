table {
  width: 100%;
  border-spacing: 0;

  th, td {
    font-size: rem-calc(13px);

    &.delete {
      background: $rose;
    }
  }

  thead {
    tr {
      background: $offset;
      color: $white;
    }
  }

  tr {
    th {
      font-weight: 600;
      color: $white;
      text-align: left;
      text-transform: uppercase;
      padding: 5px;
      letter-spacing: 1px;
      border-right: 1px solid $white;

      @include breakpoint(medium) {
        padding: 5px 10px;
      }

      @include breakpoint(large) {
        padding: 10px 20px;
      }
    }

    &.dulled {
      background: $light;

      th {
        border: 0;
        color: $vb-black;
        font-weight: 600;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid $white;

      td {
        padding: 16px;
        border-right: 2px solid $lighter;

        &::last-child {
          border-right: none;
        }

        @include breakpoint(small down) {
          padding: 5px;
        }

        &.no-border {
          border-right: none;
        }

        .icon-view {
          display: block;
          margin: 0 auto;
          max-width: 30px;
          text-align: center;

          img {
            width: 75%;
          }
        }

        .button.mini {
          margin: 0 auto;
        }

        &.main-cell {
          color: $brand;

          a {
            text-transform: none;
          }
        }

        &.bold {
          font-weight: 600;
          text-transform: uppercase;
        }

        &.light {
          color: $light;
        }        

        &.upper {
          text-transform: uppercase;
          span {
            color: $offset;
            text-transform: none;
          }
        }

        &.source {
          color: $brand;
          text-transform: uppercase;
          font-size: rem-calc(9);
        }

        &.direct {
          color: $brand;
          text-align: center;
        }

        &.price {
          color: $brand;
          text-align: center;
          font-size: rem-calc(9);

          span {
            font-size: rem-calc(20);
            font-weight: 600;
            letter-spacing: 1px;
          }
        }

        a {
          text-transform: uppercase;
          font-weight: 600;

          &.link {
            font-weight: 500;
            font-size: rem-calc(16px);
            margin: 0 -9px 10px 0;
            text-transform: uppercase;
          }
        }

      }

      &.action {
        background: $light-brand;
        box-shadow: 0 -1px 0 $white;

        td {
          font-weight: 600;
          border-right: 2px solid $white;
          border-bottom: 2px solid $white;

          &.no-border {
            border-right: none;
          }          
        }
        
        td:first-child {
          border-left: 4px solid $brand;
        }

        @include breakpoint(small down) {
          td:nth-child(2) {
            border-left: 4px solid $brand;
          }
        }
      }
    }
  }

  a.button.large, a.button.small {
    min-width: 0;
  }
}

tr.spacer {
  background: none;
  td {
    height: 20px;
  }
}

td {
  font-size: 0.9rem;
  color: $vb-black;
}

.table-heading {
  padding: 0 15px;
  text-align: left;
  h2 {
    display: inline-block;
    vertical-align: sub;
    margin-left: 0px;
    margin-bottom: 10px;
  }
}
.table-info {
  text-align: right;
  padding: 0 15px;
  p {
    color: $darker;
    display: inline-block;
    margin: 1.6rem 0;
  }
}

div.table-responsive {
  overflow-x: auto;
  max-height: 435px;
}


//Tabs on table styling
.tabs-table {
  background: $brand;
  padding-bottom: 20px;

  thead tr th {
    padding-top: 8px;
    padding-bottom: 8px;
    background: $brand;
    cursor: pointer;
  }

  .tabs-title {
    float: none;

    a {
      color: $white;  
      display: inline;
      padding: 0;

      &:hover {
        color: $white;
      }
    }
  }

  .is-active {
    background: $offset;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: -20px;
      margin: auto;
      left: 0;
      right: 0;
      height: 20px;
      width: 20px;
      border-top: 12px solid $offset;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
    }

    &.tabs-title > a, &.tabs-title a:hover, &.tabs-title a:focus, &.tabs-title a[aria-selected='true'] {
      color: $white;
    }
  }
}

.reveal table {
  tr {
    th {
      text-align: right;
      background: $white;
    }
    td {
      text-align: left;
      border-right: none;

      .button.mini {
        margin-left: 0;
      }
    }
  }
}