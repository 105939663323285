.closed {
  @include breakpoint(medium) {
    display: none;
  }
  .vertical {
    transition: all 0.5s ease-in-out;
    transform: rotate(-90deg);
  }
  .horizontal {
    transition: all 0.5s ease-in-out;
    transform: rotate(-90deg);
    opacity: 1;
  }
}

.opened {
  opacity: 1;
  .vertical {
    transition: all 0.5s ease-in-out;
    transform: rotate(90deg);
  }
  .horizontal {
    transition: all 0.5s ease-in-out;
    transform: rotate(90deg);
    opacity: 0;
  }
}

.circle-plus {
  position: absolute;
  right: 10px;
  z-index: 1;
  width: 100%;
  height: 70px;
  top: 7px;

  @include breakpoint(small only) {
    display: block;
  }
}

.circle-plus .circle {
  position: relative;
  width: 2em;
  height: 2em;
  border-radius: 100%;
  right: -90%;

  @include breakpoint(490px) {
    right: -93%;
  }
  @include breakpoint(600px) {
    right: -94%;
  }
  @include breakpoint(700px) {
    right: -96%;
  } 
}
.circle-plus .circle .horizontal {
  position: absolute;
  background-color: $brand;
  width: 20px;
  height: 3px;
  left: 50%;
  margin-left: -10px;
  top: 50%;
  margin-top: -1.5px;
}
.circle-plus .circle .vertical {
  position: absolute;
  background-color: $brand;
  width: 3px;
  height: 20px;
  left: 50%;
  margin-left: -1.5px;
  top: 50%;
  margin-top: -10px;
}

.mobile-slide-toggle {
  @include breakpoint(small down) {
    display: none;
  }
}
