.icon-grid-small {
  margin: 50px 0;
  .cell {
    text-align: center;
    figure {
      height: 50px;
      img {
        width: 50px;
        height: auto;

        &.reg-steps {
          width: 70px;
          margin-top: 20px;
        }
      }
    }
  }
}

span.icon {
  position: relative;
  display: block;
  width: 19px;
  height: 19px;

  &.delete {
    margin: 0 auto;
  }

  &.delete,
  &.add {
    border-radius: 3px;
    border: solid 1px $rose;

    &::before,
    &::after {
      content: "";
      position: absolute;
      background: $rose;
      transform: rotate(45deg);
    }

    &::before {
      left: 8px;
      top: 2px;
      width: 1px;
      height: calc(100% - 4px);
    }

    &::after {
      top: 8px;
      left: 2px;
      height: 1px;
      width: calc(100% - 4px);
    }

    &.inverse {
      background: $rose;

      &::before,
      &::after {
        background: $white;
      }

    }
  }

  &.add {
    transform: rotate(45deg);
  }

  &.padlock {
    background-image: url(../../../img/icon-padlock.svg);
    background-repeat: no-repeat;
  }

  &.disabled {
    border-color: $dark;
    pointer-events: none;

    &::before,
    &::after {
      background: $dark;
    }
  }

}
