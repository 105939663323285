/// Creates a scaling transition. A scale of `1` means the element is the same size. Larger numbers make the element bigger, while numbers less than 1 make the element smaller.
/// @param {Number} $from [1.5] - Size to start at.
/// @param {Number} $to [1] - Size to end at.
@function zoom(
  $from: 0,
  $to: 1
) {
  $keyframes: (
    name: 'scale-#{$to}-to-#{$from}',
    0: (transform: scale($from)),
    100: (transform: scale($to)),
  );

  @return $keyframes;
}
